import AdminPanel from "./Admin";
import MembersContent from "./MembersContent";

const Members = ({}) => {
  return (
    <AdminPanel>
      <MembersContent />
    </AdminPanel>
  );
};

export default Members;
