import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormControlLabel,
  Switch,
  Alert,
  CircularProgress,
  Typography,
  Divider,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { domain } from "../../Components/config";

const EditPurchaseForm = ({ purchaseId, onClose, onSuccess }) => {
  const [formData, setFormData] = useState({
    email: "",
    mobile: "",
    planType: "",
    planName: "",
    serverConfig: {
      domainName: "",
      databaseName: "",
      databaseURL: "",
      aaPanelLink: "",
      serverStatus: "ACTIVE",
    },
    paymentDetails: {
      paidAmount: "",
      dueAmount: "",
      currency: "USD",
    },
    validityPeriod: {
      startDate: null,
      endDate: null,
      autoRenewal: false,
    },
    status: "ACTIVE",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    fetchPurchaseDetails();
  }, [purchaseId]);

  const fetchPurchaseDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(
        `${domain}/purchased-users/${purchaseId}`
      );
      if (response.data.success) {
        const purchase = response.data.data;
        setFormData({
          email: purchase.contact?.email || "",
          mobile: purchase.contact?.mobile || "",
          planType: purchase.planType || "",
          planName: purchase.planName || "",
          serverConfig: {
            domainName: purchase.serverConfig?.domainName || "",
            databaseName: purchase.serverConfig?.databaseName || "",
            databaseURL: purchase.serverConfig?.databaseURL || "",
            aaPanelLink: purchase.serverConfig?.aaPanelLink || "",
            serverStatus: purchase.serverConfig?.serverStatus || "ACTIVE",
          },
          paymentDetails: {
            paidAmount: purchase.paymentDetails?.paidAmount || "",
            dueAmount: purchase.paymentDetails?.dueAmount || "",
            currency: purchase.paymentDetails?.currency || "USD",
          },
          validityPeriod: {
            startDate: purchase.validityPeriod?.startDate
              ? new Date(purchase.validityPeriod.startDate)
              : null,
            endDate: purchase.validityPeriod?.endDate
              ? new Date(purchase.validityPeriod.endDate)
              : null,
            autoRenewal: purchase.validityPeriod?.autoRenewal || false,
          },
          status: purchase.status || "ACTIVE",
        });
      } else {
        setError("Failed to fetch purchase details");
      }
    } catch (error) {
      setError(
        error.message || "An error occurred while fetching purchase details"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitLoading(true);
      setError(null);

      const response = await axios.put(
        `${domain}/purchased-users/${purchaseId}`,
        {
          contact: {
            email: formData.email,
            mobile: formData.mobile,
          },
          planType: formData.planType,
          planName: formData.planName,
          serverConfig: formData.serverConfig,
          paymentDetails: formData.paymentDetails,
          validityPeriod: {
            ...formData.validityPeriod,
            startDate: formData.validityPeriod.startDate?.toISOString(),
            endDate: formData.validityPeriod.endDate?.toISOString(),
          },
          status: formData.status,
        }
      );

      if (response.data.success) {
        onSuccess?.();
        onClose?.();
      } else {
        setError("Failed to update purchase details");
      }
    } catch (error) {
      setError(
        error.message || "An error occurred while updating purchase details"
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleInputChange = (section, field) => (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (section) {
      setFormData((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ p: 2 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {/* Contact Information */}
        <Typography variant="h6" sx={{ mb: 2, color: "#274BA6" }}>
          Contact Information
        </Typography>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              value={formData.email}
              onChange={handleInputChange(null, "email")}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Mobile"
              value={formData.mobile}
              onChange={handleInputChange(null, "mobile")}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        {/* Plan Details */}
        <Typography variant="h6" sx={{ mb: 2, color: "#274BA6" }}>
          Plan Details
        </Typography>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth required>
              <InputLabel>Plan Type</InputLabel>
              <Select
                value={formData.planType}
                label="Plan Type"
                onChange={handleInputChange(null, "planType")}
              >
                <MenuItem value="TRIAL">Trial</MenuItem>
                <MenuItem value="MONTHLY">Monthly</MenuItem>
                <MenuItem value="ANNUAL">Annual</MenuItem>
                <MenuItem value="LIFETIME">Lifetime</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Plan Name"
              value={formData.planName}
              onChange={handleInputChange(null, "planName")}
              required
            />
          </Grid>
        </Grid>

        {/* Server Configuration */}
        <Typography variant="h6" sx={{ mb: 2, color: "#274BA6" }}>
          Server Configuration
        </Typography>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Domain Name"
              value={formData.serverConfig.domainName}
              onChange={handleInputChange("serverConfig", "domainName")}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Database Name"
              value={formData.serverConfig.databaseName}
              onChange={handleInputChange("serverConfig", "databaseName")}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Database URL"
              value={formData.serverConfig.databaseURL}
              onChange={handleInputChange("serverConfig", "databaseURL")}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="aaPanel Link"
              value={formData.serverConfig.aaPanelLink}
              onChange={handleInputChange("serverConfig", "aaPanelLink")}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth required>
              <InputLabel>Server Status</InputLabel>
              <Select
                value={formData.serverConfig.serverStatus}
                label="Server Status"
                onChange={handleInputChange("serverConfig", "serverStatus")}
              >
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">Inactive</MenuItem>
                <MenuItem value="MAINTENANCE">Maintenance</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Payment Details */}
        <Typography variant="h6" sx={{ mb: 2, color: "#274BA6" }}>
          Payment Details
        </Typography>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              type="number"
              label="Paid Amount"
              value={formData.paymentDetails.paidAmount}
              onChange={handleInputChange("paymentDetails", "paidAmount")}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              type="number"
              label="Due Amount"
              value={formData.paymentDetails.dueAmount}
              onChange={handleInputChange("paymentDetails", "dueAmount")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth required>
              <InputLabel>Currency</InputLabel>
              <Select
                value={formData.paymentDetails.currency}
                label="Currency"
                onChange={handleInputChange("paymentDetails", "currency")}
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="GBP">GBP</MenuItem>
                <MenuItem value="INR">INR</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Validity Period */}
        <Typography variant="h6" sx={{ mb: 2, color: "#274BA6" }}>
          Validity Period
        </Typography>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={formData.validityPeriod.startDate}
                onChange={(newValue) => {
                  setFormData((prev) => ({
                    ...prev,
                    validityPeriod: {
                      ...prev.validityPeriod,
                      startDate: newValue,
                    },
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth required />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                value={formData.validityPeriod.endDate}
                onChange={(newValue) => {
                  setFormData((prev) => ({
                    ...prev,
                    validityPeriod: {
                      ...prev.validityPeriod,
                      endDate: newValue,
                    },
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth required />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.validityPeriod.autoRenewal}
                  onChange={handleInputChange("validityPeriod", "autoRenewal")}
                />
              }
              label="Auto Renewal"
            />
          </Grid>
        </Grid>

        {/* Status */}
        <Typography variant="h6" sx={{ mb: 2, color: "#274BA6" }}>
          Status
        </Typography>
        <FormControl fullWidth required sx={{ mb: 3 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={formData.status}
            label="Status"
            onChange={handleInputChange(null, "status")}
          >
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
            <MenuItem value="SUSPENDED">Suspended</MenuItem>
          </Select>
        </FormControl>

        {/* Form Actions */}
        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={onClose} disabled={submitLoading}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={submitLoading}
            sx={{
              bgcolor: "#274BA6",
              "&:hover": {
                bgcolor: "#1a3474",
              },
            }}
          >
            {submitLoading ? <CircularProgress size={24} /> : "Update Purchase"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default EditPurchaseForm;
