import AdminPanel from "./Admin";
import Dashboard from "./Dashboard";

const Members = ({}) => {
  return (
    <AdminPanel>
      <Dashboard />
    </AdminPanel>
  );
};

export default Members;
