import React from "react";
import IllegalBetsMain from "./IllegalBetsMain";
import AdminPanel from "./Admin";

function IllegalBets() {
  return (
    <AdminPanel>
      <IllegalBetsMain />
    </AdminPanel>
  );
}

export default IllegalBets;
