import AdminPanel from "./Admin";
import K3GamesContent from "./K3GamesContent";

const K3 = ({}) => {
  return (
    <AdminPanel>
      <K3GamesContent />
    </AdminPanel>
  );
};

export default K3;
