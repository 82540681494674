import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Alert,
  AlertTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  useTheme,
  Paper,
} from "@mui/material";

import {
  Crown,
  Gift,
  Trophy,
  Clock,
  Users,
  TrendingUp,
  Timer,
  Lock,
  ChevronRight,
  Info,
  Percent,
  Wallet,
  BadgeCheck,
} from "lucide-react";

const AdditionalFeaturesMain = ({ isDemoUser = false }) => {
  const [activeFeature, setActiveFeature] = useState(null);
  const [openGuide, setOpenGuide] = useState(false);
  const theme = useTheme();

  const features = [
    {
      title: "VIP Levels Management",
      description: "Configure tiered VIP system with rewards",
      icon: <Crown />,
      color: theme.palette.primary.main,
      gradient: `linear-gradient(135deg, ${theme.palette.primary.light}22, ${theme.palette.primary.dark}11)`,
      guide: [
        {
          label: "Configure VIP Tiers",
          description: "Set up progressive VIP levels",
          detail:
            "Create 5 distinct levels from Bronze to Diamond with increasing benefits",
          benefit: "Encourages user loyalty and platform engagement",
          extraInfo: [
            {
              title: "Tier Structure",
              items: [
                "Bronze: Entry level - ₹1,000 minimum deposit",
                "Silver: Mid tier - ₹5,000 minimum deposit",
                "Gold: High tier - ₹15,000 minimum deposit",
                "Platinum: Elite tier - ₹30,000 minimum deposit",
                "Diamond: Premium tier - ₹50,000 minimum deposit",
              ],
            },
            {
              title: "Tier Benefits",
              items: [
                "Exclusive customer support access",
                "Enhanced withdrawal limits",
                "Special event invitations",
                "Birthday bonuses",
                "Personalized rewards",
              ],
            },
          ],
        },
        {
          label: "Define Deposit Thresholds",
          description: "Set minimum deposit requirements per level",
          detail: "Range: ₹1,000 - ₹50,000 with incremental steps",
          benefit: "Drives higher deposit volumes and user commitment",
          extraInfo: [
            {
              title: "Threshold Management",
              items: [
                "Monthly maintenance requirement: 50% of entry deposit",
                "Rolling 30-day deposit calculation",
                "Grace period: 7 days before tier downgrade",
                "Automatic tier upgrades on qualification",
              ],
            },
          ],
        },
        {
          label: "Structure Rewards",
          description: "Configure multi-tier reward system",
          detail:
            "One-time bonuses: ₹100 - ₹2,000, Monthly rewards: ₹50 - ₹500",
          benefit: "Provides ongoing incentives for continued engagement",
          extraInfo: [
            {
              title: "Reward Types",
              items: [
                "Welcome bonus per tier",
                "Monthly cashback: 0.5% - 2.5%",
                "Birthday bonus: ₹100 - ₹1,000",
                "Anniversary rewards",
                "Special event bonuses",
              ],
            },
          ],
        },
      ],
      benefits: [
        {
          title: "User Retention",
          description: "Keep high-value users engaged with exclusive benefits",
          icon: <Users />,
        },
        {
          title: "Revenue Growth",
          description: "Drive higher deposits through tier progression",
          icon: <TrendingUp />,
        },
      ],
    },
    {
      title: "First Deposit Bonus",
      description: "Set welcome bonuses for new users",
      icon: <Gift />,
      color: theme.palette.success.main,
      gradient: `linear-gradient(135deg, ${theme.palette.success.light}22, ${theme.palette.success.dark}11)`,
      guide: [
        {
          label: "Configure Welcome Bonus",
          description: "Set up initial deposit bonus structure",
          detail: "Bonus range: 50% - 100% of first deposit up to ₹5,000",
          benefit: "Attracts new users and encourages higher initial deposits",
          extraInfo: [
            {
              title: "Bonus Structure",
              items: [
                "Minimum deposit: ₹1,000",
                "Maximum bonus: ₹5,000",
                "Wagering requirement: 10x bonus amount",
                "Validity period: 7 days",
                "Eligible games: All except selected table games",
              ],
            },
          ],
        },
        {
          label: "Define Wagering Requirements",
          description: "Set fair and attractive wagering conditions",
          detail: "Standard: 10x bonus amount, Time limit: 7 days",
          benefit: "Balances user attraction with business sustainability",
          extraInfo: [
            {
              title: "Wagering Rules",
              items: [
                "Game contribution rates vary by type",
                "Maximum bet restrictions during wagering",
                "Clear progress tracking",
                "Partial withdrawal options",
                "Failed wagering consequences",
              ],
            },
          ],
        },
        {
          label: "Set Bonus Terms",
          description: "Define clear bonus terms and conditions",
          detail:
            "Specify eligible games, maximum bets, and withdrawal conditions",
          benefit: "Ensures transparency and reduces support queries",
          extraInfo: [
            {
              title: "Key Terms",
              items: [
                "Game eligibility criteria",
                "Maximum bet limits",
                "Withdrawal restrictions",
                "Bonus abuse prevention",
                "Multiple account policies",
              ],
            },
          ],
        },
      ],
      benefits: [
        {
          title: "User Acquisition",
          description: "Attract new users with competitive welcome offers",
          icon: <Users />,
        },
        {
          title: "Initial Engagement",
          description:
            "Drive early platform activity through wagering requirements",
          icon: <TrendingUp />,
        },
      ],
    },
    {
      title: "Activity Rewards",
      description: "Configure betting rewards system",
      icon: <Trophy />,
      color: theme.palette.warning.main,
      gradient: `linear-gradient(135deg, ${theme.palette.warning.light}22, ${theme.palette.warning.dark}11)`,
      guide: [
        {
          label: "Set Up Activity Points",
          description: "Configure point earning system",
          detail: "1 point per ₹100 wagered, bonus multipliers for VIP levels",
          benefit: "Encourages regular platform activity",
          extraInfo: [
            {
              title: "Points System",
              items: [
                "Base earning rate: 1 point per ₹100",
                "VIP multipliers: 1.2x - 2x",
                "Special event multipliers",
                "Point expiry: 90 days",
                "Minimum redemption: 100 points",
              ],
            },
          ],
        },
        {
          label: "Configure Rewards Catalog",
          description: "Set up redeemable rewards",
          detail: "Cash bonuses, free bets, and special prizes",
          benefit: "Provides attractive redemption options",
          extraInfo: [
            {
              title: "Reward Options",
              items: [
                "Cash bonuses: ₹100 - ₹10,000",
                "Free bets: ₹50 - ₹1,000",
                "Special event tickets",
                "Merchandise rewards",
                "VIP experiences",
              ],
            },
          ],
        },
        {
          label: "Define Special Events",
          description: "Set up promotional events with boosted rewards",
          detail: "Double points weekends, happy hours, special tournaments",
          benefit: "Drives activity during specific periods",
          extraInfo: [
            {
              title: "Event Types",
              items: [
                "Weekend boost events",
                "Happy hour promotions",
                "Tournament rewards",
                "Seasonal specials",
                "VIP exclusive events",
              ],
            },
          ],
        },
      ],
      benefits: [
        {
          title: "Regular Engagement",
          description: "Keep users active with ongoing rewards",
          icon: <Timer />,
        },
        {
          title: "Platform Activity",
          description: "Drive higher betting volumes through rewards",
          icon: <TrendingUp />,
        },
      ],
    },
    {
      title: "Withdrawal Settings",
      description: "Manage withdrawal limits and timing",
      icon: <Clock />,
      color: theme.palette.error.main,
      gradient: `linear-gradient(135deg, ${theme.palette.error.light}22, ${theme.palette.error.dark}11)`,
      guide: [
        {
          label: "Set Withdrawal Limits",
          description: "Configure daily, weekly, and monthly limits",
          detail: "Daily: ₹10,000 - ₹100,000 based on VIP level",
          benefit: "Ensures responsible gaming and platform sustainability",
          extraInfo: [
            {
              title: "Limit Structure",
              items: [
                "Daily limits by VIP level",
                "Weekly accumulation caps",
                "Monthly maximum thresholds",
                "Special limits for verified users",
                "VIP override procedures",
              ],
            },
          ],
        },
        {
          label: "Configure Processing Times",
          description: "Set withdrawal processing schedules",
          detail: "Standard: 24-48 hours, VIP: 2-12 hours",
          benefit: "Balances operational efficiency with user satisfaction",
          extraInfo: [
            {
              title: "Processing Rules",
              items: [
                "Standard processing windows",
                "VIP priority processing",
                "Weekend/holiday handling",
                "Manual review triggers",
                "Automatic approval conditions",
              ],
            },
          ],
        },
        {
          label: "Define Verification Requirements",
          description: "Set up KYC and verification rules",
          detail: "Document requirements and verification levels",
          benefit: "Ensures compliance and prevents fraud",
          extraInfo: [
            {
              title: "Verification Levels",
              items: [
                "Basic KYC requirements",
                "Enhanced due diligence triggers",
                "Document validity periods",
                "Re-verification conditions",
                "Verification bypass rules",
              ],
            },
          ],
        },
      ],
      benefits: [
        {
          title: "Risk Management",
          description: "Control withdrawal patterns and prevent abuse",
          icon: <Lock />,
        },
        {
          title: "User Trust",
          description: "Build confidence with reliable processing",
          icon: <BadgeCheck />,
        },
      ],
    },
  ];

  const FeatureCard = ({ feature }) => (
    <Card
      onClick={() => {
        setActiveFeature(feature);
        setOpenGuide(true);
      }}
      sx={{
        height: "100%",
        cursor: "pointer",
        background: feature.gradient,
        transition: "all 0.3s ease",
        position: "relative",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: theme.shadows[4],
          "& .arrow-icon": {
            transform: "translateX(4px)",
            opacity: 1,
          },
        },
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Box display="flex" alignItems="flex-start" mb={2}>
          <Box
            sx={{
              background: feature.color,
              borderRadius: "12px",
              p: 1.5,
              mr: 2,
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {feature.icon}
          </Box>
          <Box flex={1}>
            <Box display="flex" alignItems="center" mb={0.5}>
              <Typography variant="h6" component="h3" sx={{ fontWeight: 600 }}>
                {feature.title}
              </Typography>
              {isDemoUser && (
                <Tooltip title="Demo Version - Limited Access">
                  <Lock
                    sx={{
                      ml: 1,
                      color: theme.palette.warning.main,
                      width: 20,
                      height: 20,
                    }}
                  />
                </Tooltip>
              )}
            </Box>
            <Typography variant="body2" color="text.secondary">
              {feature.description}
            </Typography>
          </Box>
        </Box>
        <Box
          className="arrow-icon"
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
            opacity: 0,
            transition: "all 0.3s ease",
          }}
        >
          <ChevronRight />
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ p: 4, maxWidth: "1400px", margin: "0 auto" }}>
    <Box mb={4}>
  <Typography
    variant="h4"
    component="h1"
    gutterBottom
    sx={{
      fontWeight: 600,
      fontSize: "2rem",
      color: theme.palette.text.primary,
    }}
  >
    Admin Features Access
    <Tooltip title="Demo Version - Limited Access">
      <Lock
        sx={{
          ml: 1,
          color: theme.palette.warning.main,
          width: 24,
          height: 24,
          verticalAlign: "middle",
        }}
      />
    </Tooltip>
  </Typography>
  <Typography
    variant="subtitle1"
    color="text.secondary"
    sx={{ maxWidth: 600 }}
  >
    Access advanced admin settings to manage and optimize platform functionality.
    Full access requires a purchased account.
  </Typography>
</Box>


      <Grid container spacing={3}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <FeatureCard feature={feature} />
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openGuide}
        onClose={() => setOpenGuide(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "12px",
            overflow: "hidden",
          },
        }}
      >
        {activeFeature && (
          <>
            <DialogTitle sx={{ p: 3, pb: 2 }}>
              <Box display="flex" alignItems="center">
                <Box
                  sx={{
                    background: activeFeature.color,
                    borderRadius: "8px",
                    p: 1,
                    mr: 2,
                    color: "white",
                  }}
                >
                  {activeFeature.icon}
                </Box>
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ fontWeight: 600 }}
                >
                  {activeFeature.title} Configuration
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent sx={{ p: 3 }}>
              <Alert
                severity="info"
                sx={{
                  mb: 3,
                  borderRadius: "8px",
                  "& .MuiAlert-message": {
                    width: "100%",
                  },
                }}
              >
                <AlertTitle>Configuration Guide</AlertTitle>
                Follow these steps to optimize your{" "}
                {activeFeature.title.toLowerCase()} settings.
              </Alert>

              <Stepper orientation="vertical" sx={{ mb: 4 }}>
                {activeFeature.guide.map((step, index) => (
                  <Step key={index} active={true}>
                    <StepLabel>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {step.label}
                      </Typography>
                    </StepLabel>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      <Box
                        sx={{
                          my: 2,
                          p: 2,
                          bgcolor: "grey.50",
                          borderRadius: "8px",
                          border: `1px solid ${theme.palette.grey[200]}`,
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          <Box component="span" sx={{ fontWeight: 600 }}>
                            Expert Tip:
                          </Box>{" "}
                          {step.detail}
                        </Typography>
                      </Box>

                      {step.extraInfo &&
                        step.extraInfo.map((info, infoIndex) => (
                          <Paper
                            key={infoIndex}
                            elevation={0}
                            sx={{
                              p: 2,
                              my: 2,
                              bgcolor: "background.default",
                              border: `1px solid ${theme.palette.divider}`,
                              borderRadius: "8px",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 600, mb: 1 }}
                            >
                              {info.title}
                            </Typography>
                            <List dense>
                              {info.items.map((item, itemIndex) => (
                                <ListItem key={itemIndex}>
                                  <ListItemIcon sx={{ minWidth: 32 }}>
                                    <Info
                                      sx={{
                                        width: 16,
                                        height: 16,
                                        color: theme.palette.primary.main,
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={item}
                                    primaryTypographyProps={{
                                      variant: "body2",
                                      color: "text.secondary",
                                    }}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </Paper>
                        ))}

                      <Typography
                        variant="body2"
                        sx={{
                          color: activeFeature.color,
                          mt: 2,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <BadgeCheck size={16} />
                        <Box component="span" sx={{ fontWeight: 600 }}>
                          Benefit:
                        </Box>{" "}
                        {step.benefit}
                      </Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>

              {activeFeature.benefits && (
                <Box sx={{ mt: 4 }}>
                  <Divider sx={{ mb: 3 }} />
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Key Benefits
                  </Typography>
                  <Grid container spacing={2}>
                    {activeFeature.benefits.map((benefit, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <Paper
                          elevation={0}
                          sx={{
                            p: 2,
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: "8px",
                            height: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                            }}
                          >
                            <Box
                              sx={{
                                mr: 1,
                                color: activeFeature.color,
                                display: "flex",
                              }}
                            >
                              {benefit.icon}
                            </Box>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 600 }}
                            >
                              {benefit.title}
                            </Typography>
                          </Box>
                          <Typography variant="body2" color="text.secondary">
                            {benefit.description}
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}

              {isDemoUser && (
                <Alert
                  severity="warning"
                  sx={{
                    mt: 3,
                    borderRadius: "8px",
                  }}
                >
                  <AlertTitle>Demo Limitations</AlertTitle>
                  Some features are limited in demo mode. Upgrade to access full
                  configuration capabilities.
                </Alert>
              )}
            </DialogContent>
            <DialogActions sx={{ p: 3, pt: 2 }}>
              <Button
                onClick={() => setOpenGuide(false)}
                sx={{
                  mr: 1,
                  color: theme.palette.text.secondary,
                }}
              >
                Close
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // Handle configuration save
                  setOpenGuide(false);
                }}
                disabled={isDemoUser}
                sx={{
                  borderRadius: "8px",
                  textTransform: "none",
                  px: 3,
                }}
              >
                Save Configuration
              </Button> */}
            </DialogActions>
          </>
        )}
      </Dialog>

      {isDemoUser && (
        <Alert
          severity="info"
          sx={{
            mt: 4,
            borderRadius: "8px",
          }}
        >
          <AlertTitle>Demo Version</AlertTitle>
          You are currently viewing the demo version. Upgrade to access full
          configuration capabilities and start optimizing your platform's
          performance.
        </Alert>
      )}
    </Box>
  );
};

export default AdditionalFeaturesMain;
