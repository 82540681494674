import AdminPanel from "./Admin";
import DepositPendingRequestContent from "./DepositPendingRequestContent";

const DepositPendingRequest = ({}) => {
  return (
    <AdminPanel>
      <DepositPendingRequestContent />
    </AdminPanel>
  );
};

export default DepositPendingRequest;
