import React from "react";
import BottomNavigationArea from "../Components/BottomNavigation";
import GameStatisticsMain from "./GameStatisticsMain";

function GameStatistics() {
  return (
    <div>
      <GameStatisticsMain></GameStatisticsMain>
    </div>
  );
}

export default GameStatistics;
