import AdminPanel from "./Admin";
import WalletUpdateMain from "./WalletUpdateMain";

const WalletUpdate = ({}) => {
  return (
    <AdminPanel>
      <WalletUpdateMain />
    </AdminPanel>
  );
};

export default WalletUpdate;
