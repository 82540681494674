import AdminPanel from "./Admin";
import WithdrawContent from "./WithdrawContent";

const Withdraw = ({}) => {
  return (
    <AdminPanel>
      <WithdrawContent />
    </AdminPanel>
  );
};

export default Withdraw;
