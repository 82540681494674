// src/components/RegisterForm.jsx
import React from "react";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  IconButton,
  MenuItem,
  CircularProgress,
  Divider,
  Card,
  CardContent,
  CardActions,
  Fade,
  Alert,
  useTheme,
  Dialog,
  Snackbar,
  InputAdornment,
} from "@mui/material";
import {
  Phone as PhoneIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  Lock as LockIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { motion, AnimatePresence } from "framer-motion";
import { PlanSelection, PlanConfirmation } from "./ClientRegisterPages";
import { domain } from "../Components/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { styled } from "@mui/material/styles";

const BackgroundBox = styled(Box)(({ theme }) => ({
  width: "100%", // Default width for small and medium screens
  maxHeight: "100vh",
  maxWidth: "100vw",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // padding: "20px",
  paddingTop: "30px",
  paddingBottom: "30px",
  backgroundImage: `url('/assets/3623.jpg')`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  [theme.breakpoints.up("md")]: {
    width: "50%", // For screens larger than medium (md)
  },
}));

// Enhanced styled components with responsive design
const SplitLayout = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  width: "100%",
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const CarouselWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  overflow: "hidden",
}));

const CarouselSlide = styled(motion.div)({
  position: "absolute",
  width: "100%",
  height: "100%",
  willChange: "transform",
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

const CarouselSection = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "40vh",
  position: "relative",
  overflow: "hidden",
  [theme.breakpoints.up("sm")]: {
    height: "45vh",
  },
  [theme.breakpoints.up("md")]: {
    width: "75%",
    height: "100vh",
  },
}));

const FormSection = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "60vh",
  background: "linear-gradient(to bottom right, #f5f5f5, #ffffff)",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  [theme.breakpoints.up("md")]: {
    width: "50%",
    height: "100vh",
    overflow: "auto",
  },
}));
const StyledCard = styled(Card)(({ theme }) => ({
  background: "white",
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
  margin: "auto",
  maxWidth: "100%",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "90%",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "80%",
  },
}));

const FormTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: theme.spacing(1),
    fontSize: "0.9rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.9rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  "& .MuiInputAdornment-root": {
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.5rem",
      },
    },
  },
}));
const GradientButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #b06949, #682a41)",
  borderRadius: theme.spacing(1),
  color: "white",
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  fontSize: "0.9rem",
  textTransform: "none",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(4)}`,
    fontSize: "1rem",
    width: "auto",
  },
  "&:hover": {
    background: "linear-gradient(45deg, #b06949, #682a41)",
    opacity: 0.9,
  },
}));
// Carousel images array
const carouselImages = [
  "/assets/home1carousal.jpg",
  "/assets/home2carousal.jpg",
  "/assets/home3carousal.jpg",
];
const CarouselControls = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  zIndex: 2,
  [theme.breakpoints.up("sm")]: {
    bottom: theme.spacing(4),
    gap: theme.spacing(2),
  },
}));
const CarouselDot = styled(Box)(({ active, theme }) => ({
  width: 6,
  height: 6,
  borderRadius: "50%",
  backgroundColor: active ? "#ffffff" : "rgba(255, 255, 255, 0.5)",
  cursor: "pointer",
  transition: "all 0.3s ease",
  [theme.breakpoints.up("sm")]: {
    width: 8,
    height: 8,
  },
}));
const CarouselArrow = styled(IconButton)(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  padding: theme.spacing(0.5),
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 2,
  "& .MuiSvgIcon-root": {
    fontSize: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
    },
  },
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1),
  },
}));
const CarouselImage = styled(motion.div)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  overflow: "hidden",
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4),
  },
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(2),
  gridTemplateColumns: "1fr",
  [theme.breakpoints.up("sm")]: {
    gap: theme.spacing(3),
    gridTemplateColumns: "repeat(2, 1fr)",
  },
}));

const STEPS = ["Personal Details", "Plan Selection", "Confirmation"];

const RegisterForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [error, setError] = useState("");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [plans, setPlans] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password: "",
    confirmPassword: "",
    otp: "",
    dateOfBirth: null,
    gender: "",
    company: "",
    invitecode: "",
    selectedPlan: "trial",
    chosenPlanId: "",
  });

  // Update the autoplay effect for faster transitions
  useEffect(() => {
    let interval;
    if (autoPlay) {
      interval = setInterval(() => {
        setCarouselIndex((prev) => (prev + 1) % carouselImages.length);
      }, 3000); // Reduced from 5000 to 3000 for faster transitions
    }
    return () => clearInterval(interval);
  }, [autoPlay]);

  // Update the carousel navigation functions for smoother transitions
  const handlePrevSlide = () => {
    setAutoPlay(false);
    setCarouselIndex((prev) =>
      prev === 0 ? carouselImages.length - 1 : prev - 1
    );
  };

  const handleNextSlide = () => {
    setAutoPlay(false);
    setCarouselIndex((prev) => (prev + 1) % carouselImages.length);
  };

  const handleDotClick = (index) => {
    setCarouselIndex(index);
    setAutoPlay(false);
  };

  const [errors, setErrors] = useState({});

  const alertStyle = {
    padding: "15px",
    margin: "10px 0",
    borderRadius: "8px",
    fontSize: "16px",
    textAlign: "center",
    width: "100%",
  };

  const successStyle = {
    ...alertStyle,
    backgroundColor: "#4caf50",
    color: "white",
  };

  const errorStyle = {
    ...alertStyle,
    backgroundColor: "#f44336",
    color: "white",
  };

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      setOtpVerified(false);
      setOtpSent(false);
    }
    if (name === "otp") {
      setOtpVerified(false);
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }, []);

  const validatePersonalDetails = useCallback(() => {
    const newErrors = {};

    if (!formData.mobile) {
      newErrors.mobile = "Mobile is required";
    } else if (!/^\+?[\d\s-]{10,}$/.test(formData.mobile)) {
      newErrors.mobile = "Invalid mobile format";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!formData.otp && otpSent) {
      newErrors.otp = "OTP is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, otpSent]);

  const handleSendOtp = async () => {
    // Check if the mobile number is filled and valid
    if (!formData.mobile) {
      setError("Please enter a valid mobile number");
      return; // Exit the function if the mobile number is not valid
    } else if (!/^\+?[\d\s-]{10,}$/.test(formData.mobile)) {
      setError("Invalid mobile format");
      return; // Exit the function if the mobile format is invalid
    }

    setOtpLoading(true);
    try {
      const response = await axios.post(`${domain}/send-otp`, {
        mobile: parseInt(formData.mobile, 10),
      });

      if (response.data.success) {
        setOtpSent(true);
        setError(""); // Clear any previous errors
        setSuccessMessage("OTP sent successfully!"); // Set success message

        // Clear success message after 3 seconds
        setTimeout(() => {
          setSuccessMessage(""); // Clear the success message
        }, 3000);
      } else {
        setError("Failed to send OTP. Try again.");
      }
    } catch (err) {
      setError("Failed to send OTP. Please check your network connection.");
    } finally {
      setOtpLoading(false);
    }
  };
  useEffect(() => {
    if (error) {
      // Automatically hide the error message after 4 seconds
      const timer = setTimeout(() => {
        setError("");
      }, 4000);
      return () => clearTimeout(timer); // Cleanup timer on component unmount or error change
    }
  }, [error]);

  const handleVerifyOtp = useCallback(async () => {
    if (loading || !formData.otp) return;

    setLoading(true);
    setError("");
    setSuccessMessage("");

    try {
      const response = await axios.post(`${domain}/verify-otp`, {
        otp: formData.otp,
      });

      if (response.data.message === "OTP verified successfully!") {
        setOtpVerified(true);
        setSuccessMessage("OTP verified successfully!");
      } else {
        setError(response.data.message || "OTP verification failed");
      }
    } catch (err) {
      setError("Failed to verify OTP. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [formData.otp, loading]);
  const isPersonalDetailsValid = useMemo(
    () => validatePersonalDetails(),
    [formData]
  );
  const isNextButtonDisabled = useMemo(() => {
    if (activeStep === 0) {
      // On the first step, button is disabled if OTP is not sent, not entered, or not verified
      return !otpSent || !formData.otp || !otpVerified;
    }
    // For other steps, keep your existing logic
    return loading;
  }, [loading, activeStep, otpSent, formData.otp, otpVerified]);

  const buttonText = useMemo(() => {
    if (loading) {
      return <CircularProgress size={24} color="inherit" />;
    }
    if (activeStep === STEPS.length - 1) {
      return "Complete Registration";
    }
    return "Next";
  }, [loading, activeStep]);

  const handleNext = useCallback(async () => {
    // Only check OTP verification on first step
    if (activeStep === 0) {
      if (!validatePersonalDetails()) {
        return;
      }

      // Check if OTP is verified before proceeding
      if (!otpSent || !formData.otp) {
        setError("Please verify OTP first");
        return;
      }

      // Move to next step if we're on first step and all validations pass
      setActiveStep((prev) => prev + 1);
      return;
    }

    // For final step - handle registration
    if (activeStep === STEPS.length - 1) {
      setLoading(true);
      try {
        const response = await axios.post(`${domain}/trial-register`, {
          ...formData,
          chosenPlanId: formData.chosenPlanId, // Include the chosen plan ID
        });
        if (response.data.success) {
          setSuccessDialogOpen(true);
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      } catch (err) {
        setError(
          err.response?.data?.msg || "Registration failed. Please try again."
        );
      } finally {
        setLoading(false);
      }
      return;
    }

    // For all other steps, simply move to next step
    setActiveStep((prev) => prev + 1);
  }, [activeStep, formData, navigate, validatePersonalDetails, otpSent]);

  const handleBack = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  const fetchPlans = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${domain}/get-plans`);
      console.log("API Response:", response.data); // Log the entire response

      if (response.data.success) {
        setPlans(response.data.data);
        console.log("Plans set:", response.data.data); // Log the plans data
        setError("");
        setSuccessMessage("Plans loaded successfully!");

        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      } else {
        setError("Failed to load plans. Please try again.");
      }
    } catch (err) {
      console.error("Error fetching plans:", err);
      setError("Failed to load plans. Please check your network connection.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPlans();
  }, []);

  const renderPersonalDetails = () => (
    <SplitLayout>
      {/* Carousel Section */}
      <CarouselSection>
        <CarouselWrapper>
          <AnimatePresence initial={false} mode="sync">
            <CarouselSlide
              key={carouselIndex}
              initial={{
                opacity: 0,
                x: 300,
                position: "absolute",
              }}
              animate={{
                opacity: 1,
                x: 0,
                position: "absolute",
                transition: {
                  duration: 0.3,
                  ease: "easeOut",
                },
              }}
              exit={{
                opacity: 0,
                x: -300,
                position: "absolute",
                transition: {
                  duration: 0.3,
                  ease: "easeIn",
                },
              }}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            >
              <img
                src={carouselImages[carouselIndex]}
                alt={`Slide ${carouselIndex + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </CarouselSlide>
          </AnimatePresence>

          {/* Keep your existing carousel controls here */}
          <CarouselArrow
            onClick={handlePrevSlide}
            sx={{ left: (theme) => theme.spacing(2) }}
          >
            <KeyboardArrowLeftIcon />
          </CarouselArrow>

          <CarouselArrow
            onClick={handleNextSlide}
            sx={{ right: (theme) => theme.spacing(2) }}
          >
            <KeyboardArrowRightIcon />
          </CarouselArrow>

          <CarouselControls>
            {carouselImages.map((_, index) => (
              <CarouselDot
                key={index}
                active={index === carouselIndex}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </CarouselControls>
        </CarouselWrapper>
      </CarouselSection>

      {/* Form Section - Remains the same */}
      <BackgroundBox>
        <Container maxWidth="md">
          <StyledCard>
            <CardContent sx={{ p: { xs: 2, sm: 4, md: 6 } }}>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  color: "text.primary",
                  mb: { xs: 3, sm: 4 },
                }}
              >
                Create Your Account
              </Typography>

              <AnimatePresence mode="wait">
                <motion.div
                  key={activeStep}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <Box component="form" noValidate>
                    <Grid container spacing={2}>
                      {/* Name Fields */}
                      <Grid item xs={12} sm={6}>
                        <FormTextField
                          fullWidth
                          label="First Name"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon color="action" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormTextField
                          fullWidth
                          label="Last Name"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon color="action" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      {/* Mobile Number Field */}
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Mobile Number"
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleChange}
                          error={!!errors.mobile}
                          helperText={errors.mobile}
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  color="primary"
                                  disabled={otpLoading}
                                  onClick={handleSendOtp}
                                  size="small"
                                  sx={{
                                    fontSize: "14px",
                                    borderRadius: "20px",
                                    background:
                                      "linear-gradient(45deg, #b06949, #682a41)",
                                    "&:hover": {
                                      background:
                                        "linear-gradient(45deg, #b06949, #682a41)",
                                    },
                                    color: "white",
                                    textTransform: "none",
                                    boxShadow:
                                      "0 3px 5px 2px rgba(63, 81, 181, .3)",
                                  }}
                                >
                                  {otpLoading ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    "Send OTP"
                                  )}
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      {otpSent && (
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Enter OTP"
                            name="otp"
                            value={formData.otp}
                            onChange={handleChange}
                            error={!!errors.otp}
                            helperText={errors.otp}
                            required
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    onClick={handleVerifyOtp}
                                    variant="text"
                                    sx={{
                                      textTransform: "none",
                                      borderRadius: "20px",
                                      color: "white",
                                      background:
                                        "linear-gradient(45deg, #b06949, #682a41)",
                                      "&:hover": {
                                        background:
                                          "linear-gradient(45deg, #b06949, #682a41)",
                                      },
                                    }}
                                    disabled={
                                      loading || !formData.otp || otpVerified
                                    }
                                  >
                                    {loading ? (
                                      <CircularProgress size={20} />
                                    ) : otpVerified ? (
                                      "Verified"
                                    ) : (
                                      "Verify OTP"
                                    )}
                                  </Button>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      )}

                      {/* Email Field */}
                      <Grid item xs={12}>
                        <FormTextField
                          fullWidth
                          label="Email"
                          name="email"
                          type="email"
                          value={formData.email}
                          onChange={handleChange}
                          error={!!errors.email}
                          helperText={errors.email}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon color="action" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      {/* Password Fields */}
                      <Grid item xs={12}>
                        <FormTextField
                          fullWidth
                          label="Password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          value={formData.password}
                          onChange={handleChange}
                          error={!!errors.password}
                          helperText={errors.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormTextField
                          fullWidth
                          label="Confirm Password"
                          name="confirmPassword"
                          type={showConfirmPassword ? "text" : "password"}
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          error={!!errors.confirmPassword}
                          helperText={errors.confirmPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>

                    {/* Error Message */}
                    {error && (
                      <Alert severity="error" sx={{ mt: 2 }}>
                        {error}
                      </Alert>
                    )}

                    {/* Navigation Buttons */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 4,
                        flexDirection: { xs: "column", sm: "row" },
                      }}
                    >
                      {activeStep !== 0 && (
                        <Button
                          onClick={handleBack}
                          disabled={loading}
                          sx={{
                            textTransform: "none",
                            mb: { xs: 2, sm: 0 },
                          }}
                        >
                          Back
                        </Button>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          gap: { xs: 1, sm: 2 },
                          mb: { xs: 2, sm: 0 },
                        }}
                      >
                        <Button
                          onClick={() => navigate("/login")}
                          sx={{
                            textTransform: "none",
                            borderRadius: "8px",
                            px: 4,
                            py: 1.5,
                            background:
                              "linear-gradient(45deg, #b06949, #682a41)",
                            "&:hover": {
                              background:
                                "linear-gradient(45deg, #b06949, #682a41)",
                            },
                            color: "white",
                            mb: { xs: 2, sm: 0 },
                          }}
                        >
                          Skip
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          disabled={loading}
                          sx={{
                            textTransform: "none",
                            borderRadius: "8px",
                            px: 4,
                            py: 1.5,
                            background:
                              "linear-gradient(45deg, #b06949, #682a41)",
                            "&:hover": {
                              background:
                                "linear-gradient(45deg, #b06949, #682a41)",
                            },
                            color: "white",
                            mb: { xs: 2, sm: 0 },
                          }}
                        >
                          {loading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : activeStep === 2 ? (
                            "Complete Registration"
                          ) : (
                            "Next"
                          )}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </motion.div>
              </AnimatePresence>
            </CardContent>
          </StyledCard>
        </Container>
      </BackgroundBox>
    </SplitLayout>
  );

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return renderPersonalDetails();
      case 1:
        return (
          <PlanSelection
            plans={plans}
            formData={formData}
            setFormData={setFormData}
            handleBack={handleBack}
            handleNext={handleNext}
            loading={loading}
            isNextButtonDisabled={isNextButtonDisabled}
            activeStep={activeStep}
            totalSteps={STEPS.length}
          />
        );
      case 2:
        return (
          <PlanConfirmation
            plans={plans}
            formData={formData}
            handleBack={handleBack}
            handleNext={handleNext}
            loading={loading}
            activeStep={activeStep}
            totalSteps={STEPS.length}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        overflow: "hidden", // Prevent scrolling beyond 100vh
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Rest of your form content */}
      <Box sx={{ flex: 1 }}>
        <AnimatePresence mode="wait">
          <motion.div
            key={activeStep}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            {getStepContent(activeStep)}
          </motion.div>
        </AnimatePresence>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {/* Conditionally show buttons except for the first step */}
        {activeStep !== 0 && activeStep !== 1 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 4,
              mx: 4, // Added horizontal margin
              gap: 2, // Added gap between buttons
              margin: "0 auto 2rem",
            }}
          >
            <Button
              onClick={handleBack}
              disabled={loading}
              sx={{
                color: "#682a41",
                borderRadius: "10px",
                px: 3,
                py: 1.5,
                fontSize: "15px",
                fontWeight: 500,
                transition: "all 0.3s ease",
                border: "1px solid",
                borderColor: "rgba(104, 42, 65, 0.2)",
                "&:hover": {
                  backgroundColor: "rgba(104, 42, 65, 0.04)",
                  borderColor: "rgba(104, 42, 65, 0.3)",
                },
                "&:disabled": {
                  color: "rgba(104, 42, 65, 0.4)",
                  borderColor: "rgba(104, 42, 65, 0.1)",
                },
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={isNextButtonDisabled}
              sx={{
                color: "#682a41",
                borderRadius: "10px",
                px: 3,
                py: 1.5,
                height: 1.2,
                fontSize: "15px",
                fontWeight: 500,
                transition: "all 0.3s ease",
                border: "1px solid",
                borderColor: "rgba(104, 42, 65, 0.2)",
                "&:hover": {
                  backgroundColor: "rgba(104, 42, 65, 0.04)",
                  borderColor: "rgba(104, 42, 65, 0.3)",
                },
                "&:disabled": {
                  color: "rgba(104, 42, 65, 0.4)",
                  borderColor: "rgba(104, 42, 65, 0.1)",
                },
              }}
            >
             {loading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "rgba(255, 255, 255, 0.9)",
                    animation: "spin 1s linear infinite",
                    "@keyframes spin": {
                      "0%": {
                        transform: "rotate(0deg)",
                      },
                      "100%": {
                        transform: "rotate(360deg)",
                      },
                    },
                  }}
                />
              ) : activeStep === STEPS.length - 1 ? (
                "Complete Registration"
              ) : (
                "Next"
              )}
            </Button>
          </Box>
        )}

        {/* Popup on OTP success */}
        {successDialogOpen && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1300,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 2,
            }}
          >
            <Card
              elevation={24}
              sx={{
                maxWidth: 400,
                width: "100%",
                borderRadius: 3,
                overflow: "hidden",
                position: "relative",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  height: "4px",
                  background: "linear-gradient(90deg, #4CAF50, #81C784)",
                },
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <Box sx={{ textAlign: "center" }}>
                  {/* Success Icon with Pulse Animation */}
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-flex",
                      mb: 2,
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        top: -4,
                        left: -4,
                        right: -4,
                        bottom: -4,
                        borderRadius: "50%",
                        border: "2px solid #4CAF50",
                        animation: "pulse 2s infinite",
                      },
                      "@keyframes pulse": {
                        "0%": {
                          transform: "scale(0.95)",
                          opacity: 0.7,
                        },
                        "70%": {
                          transform: "scale(1.1)",
                          opacity: 0.3,
                        },
                        "100%": {
                          transform: "scale(0.95)",
                          opacity: 0,
                        },
                      },
                    }}
                  >
                    <CheckCircleIcon
                      sx={{
                        fontSize: 80,
                        color: "#4CAF50",
                        animation: "fadeIn 0.5s ease-out",
                        "@keyframes fadeIn": {
                          from: {
                            opacity: 0,
                            transform: "scale(0.8)",
                          },
                          to: {
                            opacity: 1,
                            transform: "scale(1)",
                          },
                        },
                      }}
                    />
                  </Box>

                  {/* Success Message */}
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 600,
                      color: "#2E7D32",
                      mb: 1.5,
                      animation: "slideDown 0.5s ease-out",
                      "@keyframes slideDown": {
                        from: {
                          opacity: 0,
                          transform: "translateY(-20px)",
                        },
                        to: {
                          opacity: 1,
                          transform: "translateY(0)",
                        },
                      },
                    }}
                  >
                    Registration Successful!
                  </Typography>

                  {/* Congratulatory Message */}
                  <Typography
                    variant="body1"
                    sx={{
                      color: "text.secondary",
                      mb: 3,
                      animation: "fadeIn 0.5s ease-out 0.2s both",
                    }}
                  >
                    Welcome aboard! Your account has been successfully created.
                  </Typography>

                  {/* Divider with Gradient */}
                  <Divider
                    sx={{
                      my: 2,
                      background:
                        "linear-gradient(90deg, transparent, #E8F5E9, transparent)",
                    }}
                  />

                  {/* Additional Information */}
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      animation: "fadeIn 0.5s ease-out 0.4s both",
                    }}
                  >
                    You can now access all features of your account.
                  </Typography>

                  {/* Progress Indicator */}
                  <Box
                    sx={{
                      mt: 3,
                      height: 2,
                      background: "#E8F5E9",
                      borderRadius: 1,
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        width: "30%",
                        background: "linear-gradient(90deg, #4CAF50, #81C784)",
                        animation: "progress 2s ease-out infinite",
                        "@keyframes progress": {
                          from: { transform: "translateX(-100%)" },
                          to: { transform: "translateX(400%)" },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>

      <ToastContainer />
    </Box>
  );
};

export default RegisterForm;
