import AdminPanel from "./Admin";
import WithdrawlLimitsMain from "./WithdrawlLimitsMain";

const WithdrawlLimits = ({}) => {
  return (
    <AdminPanel>
      <WithdrawlLimitsMain />
    </AdminPanel>
  );
};

export default WithdrawlLimits;
