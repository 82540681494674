import AdminPanel from "./Admin";
import BonusSettingMain from "./BonusSettingMain";

const BonusSetting = ({}) => {
  return (
    <AdminPanel>
      <BonusSettingMain />
    </AdminPanel>
  );
};

export default BonusSetting;
