import AdminPanel from "./Admin";
import CompletedRejectedWithdrawals from "./WithdrawCompleteReject";

const Withdraw = ({}) => {
  return (
    <AdminPanel>
      <CompletedRejectedWithdrawals />
    </AdminPanel>
  );
};

export default Withdraw;
