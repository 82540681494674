import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  IconButton,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  Tooltip,
  Fab,
  DialogTitle,
  DialogContent,
  useTheme,
  alpha,
  CircularProgress,
  Alert,
  Link,
} from "@mui/material";
import {
  Plus,
  Edit2,
  Trash2,
  Link as LinkIcon,
  Database,
  Server,
} from "lucide-react";
import PurchaseUserForm from "./PurchaseUserForm";
import SuperAdmin from "./SuperAdmin";
import axios from "axios";
import { domain } from "../../Components/config";
import EditPurchaseForm from "./EditPurchaseForm";

const SuperAdminAnalytics = () => {
  const [openForm, setOpenForm] = useState(false);
  const [purchases, setPurchases] = useState([]);
  // Add these new state variables after the existing useState declarations
  const [openEditForm, setOpenEditForm] = useState(false);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    fetchPurchases();
  }, []);

  const fetchPurchases = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(`${domain}/purchased-users`);
      if (response.data.success) {
        const transformedData = response.data.data.map((item) => ({
          _id: item._id,
          email: item.contact?.email,
          mobile: item.contact?.mobile,
          planType: item.planType,
          planName: item.planName,
          serverConfig: {
            domainName: item.serverConfig?.domainName,
            databaseName: item.serverConfig?.databaseName,
            databaseURL: item.serverConfig?.databaseURL,
            aaPanelLink: item.serverConfig?.aaPanelLink,
            serverStatus: item.serverConfig?.serverStatus,
          },
          createdAt: item.createdAt,
          paidAmount: item.paymentDetails?.paidAmount,
          dueAmount: item.paymentDetails?.dueAmount,
          currency: item.paymentDetails?.currency,
          status: item.status,
          validityPeriod: {
            startDate: item.validityPeriod?.startDate,
            endDate: item.validityPeriod?.endDate,
            autoRenewal: item.validityPeriod?.autoRenewal,
          },
        }));
        setPurchases(transformedData);
      } else {
        setError("Failed to fetch purchase data");
      }
    } catch (error) {
      setError(error.message || "An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  };

  const ServerDetailsCell = ({ serverConfig }) => (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Server size={16} color={theme.palette.primary.main} />
        <Typography variant="body2" fontWeight={500}>
          {serverConfig.domainName}
        </Typography>
        {serverConfig.serverStatus && (
          <Chip
            label={serverConfig.serverStatus}
            size="small"
            color={
              serverConfig.serverStatus === "ACTIVE" ? "success" : "warning"
            }
            sx={{ ml: 1 }}
          />
        )}
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <Database size={14} color={theme.palette.text.secondary} />
          <Typography variant="caption" color="text.secondary">
            {serverConfig.databaseName}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <LinkIcon size={14} color={theme.palette.text.secondary} />
          <Link
            href={serverConfig.aaPanelLink}
            target="_blank"
            variant="caption"
            sx={{
              color: theme.palette.primary.main,
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            aaPanelLink
          </Link>
        </Box>
      </Box>

      <Typography
        variant="caption"
        sx={{
          color: theme.palette.text.secondary,
          display: "block",
          wordBreak: "break-all",
        }}
      >
        DB URL: {serverConfig.databaseURL}
      </Typography>
    </Box>
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPlanTypeColor = (planType) => {
    const colors = {
      TRIAL: "info",
      MONTHLY: "success",
      ANNUAL: "warning",
      LIFETIME: "error",
    };
    return colors[planType] || "default";
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleFormSubmit = async (formData) => {
    setOpenForm(false);
    await fetchPurchases();
  };

  // Add this new handler function after the existing handler functions
  const handleEditClick = (purchaseId) => {
    setSelectedPurchaseId(purchaseId);
    setOpenEditForm(true);
  };

  const handleEditClose = () => {
    setOpenEditForm(false);
    setSelectedPurchaseId(null);
  };

  const handleEditSuccess = async () => {
    setOpenEditForm(false);
    setSelectedPurchaseId(null);
    await fetchPurchases();
  };

  return (
    <SuperAdmin>
      <Container maxWidth="xl">
        <Box sx={{ py: 4, position: "relative" }}>
          {/* Header Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              sx={{
                color: "#274BA6",
                fontWeight: 600,
                position: "relative",
                "&:after": {
                  content: '""',
                  position: "absolute",
                  bottom: -8,
                  left: 0,
                  width: 60,
                  height: 4,
                  backgroundColor: "#274BA6",
                  borderRadius: 2,
                },
              }}
            >
              User Purchase Management
            </Typography>

            <Tooltip title="Add New Purchase">
              <Fab
                size="medium"
                onClick={() => setOpenForm(true)}
                sx={{
                  boxShadow: theme.shadows[4],
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: theme.shadows[8],
                    bgcolor: "#284ca7",
                  },
                  transition: "all 0.2s",
                  bgcolor: "#274BA6",
                  color: "#ffffff",
                }}
              >
                <Plus size={24} />
              </Fab>
            </Tooltip>
          </Box>

          {/* Error Alert */}
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {/* Table Section */}
          <Paper
            elevation={0}
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
              backgroundColor: alpha(theme.palette.background.paper, 0.8),
              backdropFilter: "blur(8px)",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>Client</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Plan Info</TableCell>
                    <TableCell sx={{ fontWeight: 600, minWidth: 300 }}>
                      Server Details
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Payment Info</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                    {/* <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
                        <CircularProgress size={40} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    (rowsPerPage > 0
                      ? purchases.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : purchases
                    ).map((purchase) => (
                      <TableRow
                        key={purchase._id}
                        sx={{
                          "&:hover": {
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.05
                            ),
                          },
                        }}
                      >
                        <TableCell>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="body2" fontWeight={500}>
                              {purchase.email}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {purchase.mobile}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Chip
                              label={purchase.planType}
                              size="small"
                              color={getPlanTypeColor(purchase.planType)}
                              sx={{ fontWeight: 500, maxWidth: "fit-content" }}
                            />
                            <Typography variant="caption" color="textSecondary">
                              Valid until:{" "}
                              {formatDate(purchase.validityPeriod.endDate)}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <ServerDetailsCell
                            serverConfig={purchase.serverConfig}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {purchase.currency}
                            {purchase.paidAmount}
                          </Typography>
                          {purchase.dueAmount > 0 && (
                            <Typography variant="caption" color="error">
                              Due: {purchase.currency}
                              {purchase.dueAmount}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={purchase.status}
                            size="small"
                            color={
                              purchase.status === "ACTIVE"
                                ? "success"
                                : "default"
                            }
                          />
                        </TableCell>
                        {/* <TableCell>
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Tooltip title="Edit Purchase">
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handleEditClick(purchase._id)}
                                sx={{
                                  backgroundColor: alpha(
                                    theme.palette.primary.main,
                                    0.1
                                  ),
                                  "&:hover": {
                                    backgroundColor: alpha(
                                      theme.palette.primary.main,
                                      0.2
                                    ),
                                  },
                                }}
                              >
                                <Edit2 size={18} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Purchase">
                              <IconButton
                                size="small"
                                color="error"
                                sx={{
                                  backgroundColor: alpha(
                                    theme.palette.error.main,
                                    0.1
                                  ),
                                  "&:hover": {
                                    backgroundColor: alpha(
                                      theme.palette.error.main,
                                      0.2
                                    ),
                                  },
                                }}
                              >
                                <Trash2 size={18} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell> */}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={purchases.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          {/* Purchase Form Dialog */}
          <Dialog
            open={openForm}
            onClose={() => setOpenForm(false)}
            maxWidth="md"
            fullWidth
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: 2,
                boxShadow: theme.shadows[8],
              },
            }}
          >
            <DialogTitle
              sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
                pb: 2,
              }}
            >
              <Typography
                variant="h6"
                component="span"
                fontWeight={600}
                color={"#274BA6"}
              >
                New User Purchase
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ pt: 3 }}>
              <PurchaseUserForm
                onSubmit={handleFormSubmit}
                onCancel={() => setOpenForm(false)}
              />
            </DialogContent>
          </Dialog>

          {/* Edit Purchase Form Dialog */}
          <Dialog
            open={openEditForm}
            onClose={handleEditClose}
            maxWidth="md"
            fullWidth
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: 2,
                boxShadow: theme.shadows[8],
              },
            }}
          >
            <DialogTitle
              sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
                pb: 2,
              }}
            >
              <Typography
                variant="h6"
                component="span"
                fontWeight={600}
                color="#274BA6"
              >
                Edit User Purchase
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ pt: 3 }}>
              {selectedPurchaseId && (
                <EditPurchaseForm
                  purchaseId={selectedPurchaseId}
                  onClose={handleEditClose}
                  onSuccess={handleEditSuccess}
                />
              )}
            </DialogContent>
          </Dialog>
        </Box>
      </Container>
    </SuperAdmin>
  );
};

export default SuperAdminAnalytics;
