import {
  Box,
  Button,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Typography,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { domain } from "../../Components/config";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import SuperAdmin from "./SuperAdmin";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RefreshIcon from "@mui/icons-material/Refresh";
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';

// Styled Components
const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5",
  },
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
});

const StyledTableCell = styled(TableCell)(() => ({
  fontWeight: "bold",
  backgroundColor: "#f8f9fa",
  fontSize: "15px",
  color: "#274BA6",
}));

// Summary Card Component
const SummaryCard = ({ title, value, color, borderColor }) => (
  <Grid item xs={12} sm={4}>
    <Paper
      sx={{
        color: "#000000",
        display: "flex",
        borderRadius: "4px",
        overflow: "hidden",
        fontWeight: "bold",
        border: `1px solid ${borderColor}`,
        boxShadow: `0px 0px 8px 0px ${color}`,
      }}
    >
      <Box sx={{ flexGrow: 1, p: 2, display: "flex", alignItems: "center" }}>
        {title}
      </Box>
      <Box
        sx={{
          p: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ fontSize: "1.3rem" }}>{value}</span>
      </Box>
    </Paper>
  </Grid>
);

const SuperAdminDashboard = () => {
  // State Management
  const [clientData, setClientData] = useState(null);
  const [filteredClients, setFilteredClients] = useState([]);
  const [planFilter, setPlanFilter] = useState("ALL");
  const [statusFilter, setStatusFilter] = useState("ALL");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [summaryData, setSummaryData] = useState({
    totalClients: 0,
    active: 0,
    suspended: 0,
    activeSubscriptions: 0,
    trials: 0,
    expiredTrials: 0,
  });

  const navigate = useNavigate();

  // Fetch Initial Data
  useEffect(() => {
    const fetchClientAnalytics = async () => {
      try {
        const response = await axios.get(`${domain}/client-analytics`, {
          withCredentials: true,
        });
        setClientData(response.data);
        setFilteredClients(response.data.clients);
        calculateSummary(response.data.clients);
      } catch (error) {
        console.error("Error fetching client analytics:", error);
      }
    };
    fetchClientAnalytics();
  }, []);

  // Calculate Summary Data
  const calculateSummary = (clients) => {
    const summary = {
      totalClients: clients.length,
      active: clients.filter((c) => c.status.toUpperCase() === "ACTIVE").length,
      suspended: clients.filter((c) => c.status.toUpperCase() === "SUSPENDED")
        .length,
      activeSubscriptions: clients.filter(
        (c) =>
          c.status.toUpperCase() === "ACTIVE" &&
          c.activeSubscription.planName.toUpperCase() !== "TRIAL"
      ).length,
      trials: clients.filter(
        (c) =>
          c.status.toUpperCase() === "ACTIVE" &&
          c.activeSubscription.planName.toUpperCase() === "TRIAL"
      ).length,
      expiredTrials: clients.filter(
        (c) =>
          c.status.toUpperCase() === "EXPIRED" &&
          c.activeSubscription.planName.toUpperCase() === "TRIAL"
      ).length,
    };
    setSummaryData(summary);
  };

  // Filter Logic
  useEffect(() => {
    if (!clientData?.clients) return;

    let filtered = [...clientData.clients];

    // Plan Type Filter
    if (planFilter !== "ALL") {
      filtered = filtered.filter(
        (client) =>
          client.activeSubscription.planName.toUpperCase() === planFilter
      );
    }

    // Status Filter
    if (statusFilter !== "ALL") {
      filtered = filtered.filter(
        (client) => client.status.toUpperCase() === statusFilter
      );
    }

    // Date Range Filter
    if (startDate && endDate) {
      filtered = filtered.filter((client) => {
        const clientDate = new Date(client.createdAt);
        return clientDate >= startDate && clientDate <= endDate;
      });
    }

    setFilteredClients(filtered);
    calculateSummary(filtered);
  }, [planFilter, statusFilter, startDate, endDate, clientData]);

  // Reset Filters
  const handleResetFilters = () => {
    setPlanFilter("ALL");
    setStatusFilter("ALL");
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <SuperAdmin>
      <Box sx={{ p: 3 }}>
        {/* Header Section */}
        <Paper sx={{ p: 3, mb: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography variant="h5" fontWeight="bold" color={"#274BA6"}>
              Dashboard
            </Typography>
          </Box>

          {/* Summary Cards */}
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <SummaryCard
              title="Total Clients"
              value={summaryData.totalClients}
              color="rgba(70,52,255,0.5)"
              borderColor="#4634ff"
            />
            <SummaryCard
              title="Active Clients"
              value={summaryData.active}
              color="rgba(40,199,111,0.5)"
              borderColor="#28c76f"
            />
            <SummaryCard
              title="Suspended Clients"
              value={summaryData.suspended}
              color="rgba(235,34,34,0.5)"
              borderColor="#eb2222"
            />
            <SummaryCard
              title="Active Subscriptions"
              value={summaryData.activeSubscriptions}
              color="rgba(255,159,67,0.5)"
              borderColor="#ff9f43"
            />
            <SummaryCard
              title="Active Trials"
              value={summaryData.trials}
              color="rgba(217,128,250,0.5)"
              borderColor="#d980fa"
            />
            <SummaryCard
              title="Expired Trials"
              value={summaryData.expiredTrials}
              color="rgba(254,238,170,1)"
              borderColor="#feeaa7"
            />
          </Grid>

          {/* Filter Section */}
          <Paper sx={{ p: 3, mb: 3, backgroundColor: "#f8f9fa" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" color={"#274BA6"}>
                  Member Lookup
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleResetFilters}
                    startIcon={<RefreshIcon />}
                    size="small"
                    sx={{
                      textTransform: "none"
                    }}
                  >
                    Reset Filters
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 2,
                    alignItems: "center",
                    "& .MuiFormControl-root": {
                      minWidth: "200px",
                      flex: 1,
                    },
                  }}
                >
                  <FormControl size="small">
                    <InputLabel>Plan Type</InputLabel>
                    <Select
                      value={planFilter}
                      label="Plan Type"
                      onChange={(e) => setPlanFilter(e.target.value)}
                    >
                      <MenuItem value="ALL">All Plans</MenuItem>
                      <MenuItem value="TRIAL">Trial</MenuItem>
                      <MenuItem value="MONTHLY">Monthly</MenuItem>
                      <MenuItem value="ANNUAL">Annual</MenuItem>
                      <MenuItem value="LIFETIME">Lifetime</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl size="small">
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={statusFilter}
                      label="Status"
                      onChange={(e) => setStatusFilter(e.target.value)}
                    >
                      <MenuItem value="ALL">All Status</MenuItem>
                      <MenuItem value="ACTIVE">Active</MenuItem>
                      <MenuItem value="INACTIVE">Inactive</MenuItem>
                      <MenuItem value="EXPIRED">Expired</MenuItem>
                      <MenuItem value="SUSPENDED">Suspended</MenuItem>
                    </Select>
                  </FormControl>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={setStartDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ minWidth: "200px" }}
                        />
                      )}
                    />
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={setEndDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ minWidth: "200px" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          {/* Clients Table */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    Profile Picture
                  </StyledTableCell>
                  <StyledTableCell>Username</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Mobile</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Plan Name</StyledTableCell>
                  <StyledTableCell align="center">Details</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredClients.map((client, index) => (
                  <StyledTableRow key={index}>
                    <TableCell align="center">
                      <Avatar
                        alt={client.username}
                        src={client.profile.picture}
                        sx={{ margin: "auto" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" fontWeight="bold">
                        {client.username}
                      </Typography>
                    </TableCell>
                    <TableCell>{client.email}</TableCell>
                    <TableCell>{client.phoneNumber}</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          color:
                            client.status.toUpperCase() === "ACTIVE"
                              ? "#28c76f"
                              : client.status.toUpperCase() === "SUSPENDED"
                              ? "#eb2222"
                              : "#ff9f43",
                        }}
                      >
                        {client.status}
                      </Typography>
                    </TableCell>
                    <TableCell>{client.activeSubscription.planName}</TableCell>
                    <TableCell align="center">
                        <Button
                          variant="outlined"
                          onClick={() =>
                            navigate(
                              `/super-admin/client-detials/${client._id}`
                            )
                          }
                          sx={{
                            color: "#4634FF",
                            borderColor: "#4634FF",
                            fontWeight: "bold",
                            textTransform: "none",
                            "&:hover": {
                              border: "none",
                              backgroundColor: "#4634FF",
                              color: "#ffffff",
                            },
                          }}
                        >
                          <DesktopWindowsOutlinedIcon
                            fontSize="small"
                            sx={{ mr: 1 }}
                          />
                          Details
                        </Button>
                      </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </SuperAdmin>
  );
};

export default SuperAdminDashboard;