import React from "react";
import AdminPanel from "./Admin";
import ProfitAndLossStatsMain from "./ProfitAndLossStatsMain";

function ProfitAndLossStats() {
  return (
    <AdminPanel>
      <ProfitAndLossStatsMain />
    </AdminPanel>
  );
}

export default ProfitAndLossStats;
