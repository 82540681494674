import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  Home as HomeIcon,
  Message as MessageIcon,
  CalendarToday as CalendarIcon,
  Description as DescriptionIcon,
  Settings as SettingsIcon,
  AnalyticsOutlined as AnalyticsOutlinedIcon,
  Checklist as ChecklistIcon,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open ? 0 : `-${drawerWidth}px`,
    width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      width: "100%",
    },
  })
);

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "#0F1D40",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SuperAdmin = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Dashboard");

  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const isSmallOrMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeOrLarger = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    setOpen(isLargeOrLarger);
  }, [isLargeOrLarger]);

  const menuItems = [
    {
      name: "Dashboard",
      icon: <DashboardIcon sx={{ color: "#ffffff" }} />,
      link: "/super-admin",
    },
    {
      name: "Analytics",
      icon: <AnalyticsOutlinedIcon sx={{ color: "#ffffff" }} />,
      link: "/super-admin/analytics",
    },
    {
      name: "Plans",
      icon: <ChecklistIcon sx={{ color: "#ffffff" }} />,
      link: "/super-admin/plans",
    },
    // { name: "Messages", icon: <MessageIcon /> },
    // { name: "Calendar", icon: <CalendarIcon /> },
    // { name: "Documents", icon: <DescriptionIcon /> },
    // { name: "Settings", icon: <SettingsIcon /> },
  ];

  useEffect(() => {
    const currentPath = location.pathname;

    const matchedMenuItem = menuItems.find((item) => item.link === currentPath);
    if (matchedMenuItem) {
      setSelectedItem(matchedMenuItem.name); // Set selectedItem based on current path
    }
  }, [location.pathname]);

  const handleMenuClick = (name, link) => {
    setSelectedItem(name); // Set selected item when clicking
    navigate(link); // Then navigate to the route
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <StyledAppBar position="fixed" open={open} color="default">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }), color: "#ffffff" }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar sx={{ bgcolor: "#813256" }}>SA</Avatar>
          </Box>
        </Toolbar>
      </StyledAppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "linear-gradient( #0F1D40, #274BA6)",
            color: "#ffffff",
          },
        }}
        variant={isSmallOrMedium ? "temporary" : "persistent"}
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DrawerHeader>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              ml: 2,
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Super Admin
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftIcon sx={{ color: "#ffffff" }} />
          </IconButton>
        </DrawerHeader>

        <Divider />

        <List>
          {menuItems.map((item) => (
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                selected={selectedItem === item.name}
                onClick={() => handleMenuClick(item.name, item.link)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
};

export default SuperAdmin;
