import AdminPanel from "./Admin";
import DepositRequestContent from "./DepositRequestContent";

const DepositRequest = ({}) => {
  return (
    <AdminPanel>
      <DepositRequestContent />
    </AdminPanel>
  );
};

export default DepositRequest;
