import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  Snackbar,
  Stack,
} from "@mui/material";
import axios from "axios";
import { domain } from "../../Components/config";

const PurchaseUserForm = ({ onSubmit, onCancel }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isUserExist, setIsUserExist] = useState(false);
  const [plans, setPlans] = useState([]);
  const [step, setStep] = useState(1);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [formData, setFormData] = useState({
    clientIdentifier: "",
    identifierType: "phone",
    databaseName: "",
    databaseURL: "",
    aaPanelLink: "",
    domainName: "",
    mobile: "",
    email: "",
    paymentDetails: {
      paidAmount: 0,
      dueAmount: 0,
      paymentMethod: "CREDIT_CARD",
    },
    otherDetails: {
      notes: "",
    },
    planType: "",
  });

  // Fetch plans on component mount
  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${domain}/get-plans`);
      if (response.data.success) {
        setPlans(response.data.data);
        // Set default plan type if plans exist
        if (response.data.data.length > 0) {
          setFormData((prev) => ({
            ...prev,
            planType: response.data.data[0].planType,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
      showAlert("Error fetching plans. Please try again.", "error");
    }
  };

  const handleNext = async () => {
    setStep(2);
    await searchUserExist();
  };

  const searchUserExist = async () => {
    try {
      const response = await axios.post(`${domain}/search`, {
        phoneNumber: formData.mobile,
      });
      console.log(response.data);
      if (response.data.success) {
        setIsUserExist(true);
        const userData = response.data.data; // Assuming the user data is in response.data.data
        setFormData((prev) => ({
          ...prev,
          email: userData.email || prev.email, // Fill email if exists
          databaseName: userData.databaseName || prev.databaseName,
          databaseURL: userData.databaseURL || prev.databaseURL,
          domainName: userData.domainName || prev.domainName,
          aaPanelLink: userData.aaPanelLink || prev.aaPanelLink,
          paymentDetails: {
            ...prev.paymentDetails,
            paidAmount: userData.paidAmount || prev.paymentDetails.paidAmount,
            dueAmount: userData.dueAmount || prev.paymentDetails.dueAmount,
            paymentMethod:
              userData.paymentMethod || prev.paymentDetails.paymentMethod,
          },
          otherDetails: {
            ...prev.otherDetails,
            notes: userData.notes || prev.otherDetails.notes,
          },
        }));
      } else {
        showAlert("User  not found.", "error");
      }
    } catch (error) {
      console.error(error);
      showAlert("Error checking user existence. Please try again.", "error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setFormData((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // Ensure clientIdentifier updates to mobile number if identifierType is "phone"
    if (name === "mobile" && formData.identifierType === "phone") {
      setFormData((prev) => ({
        ...prev,
        clientIdentifier: value,
      }));
    }
  };

  const showAlert = (message, severity) => {
    setAlert({
      open: true,
      message,
      severity,
    });
  };

  const validateForm = () => {
    const errors = [];

    if (!formData.mobile || !/^[0-9]{10}$/.test(formData.mobile)) {
      errors.push("Invalid mobile number");
    }

    if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.push("Invalid email format");
    }

    if (
      !formData.databaseName ||
      !formData.databaseURL ||
      !formData.domainName
    ) {
      errors.push("All database and domain fields are required");
    }

    if (!formData.planType) {
      errors.push("Please select a plan type");
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (errors.length > 0) {
      showAlert(errors.join(". "), "error");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${domain}/purchased-users`, formData);

      if (response.data.success) {
        showAlert("Purchase completed successfully!", "success");
        if (onSubmit) onSubmit(formData);
      }
    } catch (error) {
      console.error("Purchase error:", error);
      const errorMessage =
        error.response?.data?.error || "Failed to process purchase";
      showAlert(errorMessage, "error");
    } finally {
      setLoading(false);
    }
  };

  const uniquePlanTypes = [...new Set(plans.map((plan) => plan.planType))];

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", p: 3 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {step === 1 && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Mobile Number"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                disabled={loading}
                inputProps={{ maxLength: 10 }}
                helperText="10 digits mobile number"
              />
            </Grid>
          )}

          {step === 2 && (
            <>
              {/* Plan Selection */}
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  required
                  label="Plan Type"
                  name="planType"
                  value={formData.planType}
                  onChange={handleChange}
                  disabled={loading}
                >
                  {uniquePlanTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type.charAt(0) + type.slice(1).toLowerCase()}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Contact Information */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Mobile Number"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  disabled={true}
                  inputProps={{ maxLength: 10 }}
                  helperText="10 digits mobile number"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={loading || isUserExist}
                />
              </Grid>

              {/* Database Information */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Database Name"
                  name="databaseName"
                  value={formData.databaseName}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Database URL"
                  name="databaseURL"
                  value={formData.databaseURL}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Domain Name"
                  name="domainName"
                  value={formData.domainName}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="aaPanel Link"
                  name="aaPanelLink"
                  value={formData.aaPanelLink}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              {/* Payment Details */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  label="Paid Amount"
                  name="paymentDetails.paidAmount"
                  value={formData.paymentDetails.paidAmount}
                  onChange={handleChange}
                  disabled={loading}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Due Amount"
                  name="paymentDetails.dueAmount"
                  value={formData.paymentDetails.dueAmount}
                  onChange={handleChange}
                  disabled={loading}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>

              {/* Payment Method */}
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  required
                  label="Payment Method"
                  name="paymentDetails.paymentMethod"
                  value={formData.paymentDetails.paymentMethod}
                  onChange={handleChange}
                  disabled={loading}
                >
                  <MenuItem value="CREDIT_CARD">Credit Card</MenuItem>
                  <MenuItem value="DEBIT_CARD">Debit Card</MenuItem>
                  <MenuItem value="UPI">UPI</MenuItem>
                  <MenuItem value="BANK_TRANSFER">Bank Transfer</MenuItem>
                  <MenuItem value="CASH">Cash</MenuItem>
                  <MenuItem value="OTHER">Other</MenuItem>
                </TextField>
              </Grid>

              {/* Notes */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Additional Notes"
                  name="otherDetails.notes"
                  value={formData.otherDetails.notes}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    onClick={onCancel}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                  </Button>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </form>

      {/* Alert */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      {/* Next/Previous Step Buttons */}
      {step === 1 && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          sx={{ mt: 2 }}
        >
          Next
        </Button>
      )}
      {step === 2 && (
        <Button
          variant="outlined"
          onClick={() => setStep(1)}
          sx={{ mt: 2, mr: 2 }}
        >
          Previous
        </Button>
      )}
    </Box>
  );
};

export default PurchaseUserForm;
