import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

function SuperAdminProtectedRoute({ children }) {
  const location = useLocation();
  const { isAuthenticated, isSuperAdmin } = useAuth();

  console.log("Is Super Admin:", isSuperAdmin);

  if (!isAuthenticated) {
    // If user is not logged in, redirect to login
    return <Navigate to="/login" state={{ from: location }} />;
  } else if (!isSuperAdmin) {
    // If user is not a superadmin, redirect to home/dashboard or any other page
    return <Navigate to="/" />;
  } else {
    // If user is logged in and is a superadmin, render the children
    return children;
  }
}

export default SuperAdminProtectedRoute;
