import AdminPanel from "./Admin";
import ProfileMain from "./ProfileMain";

const Profile = ({}) => {
  return (
    <AdminPanel>
      <ProfileMain />
    </AdminPanel>
  );
};

export default Profile;
