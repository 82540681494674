import React from "react";
import AdminPanel from "./Admin";
import UpdateImagesMain from "./UpdateImagesMain";

function UpdateImages() {
  return (
    <AdminPanel>
      <UpdateImagesMain />
    </AdminPanel>
  );
}

export default UpdateImages;
