import ActivityAdminMainComponent from "./ActivityAdminMainComponent";
import AdminPanel from "./Admin";

const ActivityAdminComponent = ({}) => {
  return (
    <AdminPanel>
      <ActivityAdminMainComponent />
    </AdminPanel>
  );
};

export default ActivityAdminComponent;
