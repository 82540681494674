import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Alert,
  Stack,
  Chip,
  Divider,
  useTheme,
  LinearProgress,
  Button,
  Paper,
  IconButton,
  Tooltip,
  Avatar,
  Grid,
  alpha,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  AccessTime as AccessTimeIcon,
  CalendarToday as CalendarIcon,
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon,
  AccountCircle as AccountCircleIcon,
  Rocket as RocketIcon,
  Phone as PhoneIcon,
  WhatsApp as WhatsAppIcon,
  Mail as MailIcon,
  ArrowUpward as ArrowUpwardIcon,
  Diamond as DiamondIcon,
  Star as StarIcon,
} from "@mui/icons-material";

// Styled Components
const TimeBlock = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minWidth: 100,
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
  },
}));

const GradientCard = styled(Paper)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at top right, ${alpha(
      theme.palette.common.white,
      0.1
    )} 0%, transparent 60%)`,
  },
}));

const FeatureCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  background: alpha(theme.palette.common.white, 0.1),
  backdropFilter: "blur(8px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(1),
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
  },
}));

const ContactButton = styled(IconButton)(({ theme }) => ({
  background: alpha(theme.palette.common.white, 0.1),
  color: theme.palette.common.white,
  "&:hover": {
    background: alpha(theme.palette.common.white, 0.2),
  },
}));

const SubscriptionTimer = ({ mobile, wsUrl, userData }) => {
  const theme = useTheme();
  const wsRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [error, setError] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("connecting");

  const cleanup = useCallback(() => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      try {
        wsRef.current.send(
          JSON.stringify({
            type: "stop_tracking",
            mobile,
          })
        );
      } catch (error) {
        console.error("Error sending stop tracking message:", error);
      }
    }

    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }

    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }
  }, [mobile]);

  const connectWebSocket = useCallback(() => {
    cleanup();

    const ws = new WebSocket(wsUrl);
    wsRef.current = ws;

    ws.onopen = () => {
      setConnectionStatus("connected");
      setError(null);
      ws.send(
        JSON.stringify({
          type: "track_subscription",
          mobile,
        })
      );
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        switch (data.type) {
          case "subscription_time":
            setSubscriptionData(data.data);
            setError(null);
            break;
          case "subscription_status":
            if (["EXPIRED", "NO_ACTIVE_SUBSCRIPTION"].includes(data.status)) {
              setSubscriptionData({ status: data.status });
            }
            break;
          case "error":
            setError(data.message);
            break;
          default:
            console.warn("Unhandled message type:", data.type);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    ws.onclose = (event) => {
      if (!event.wasClean) {
        setConnectionStatus("disconnected");
        reconnectTimeoutRef.current = setTimeout(() => {
          if (document.visibilityState === "visible") {
            setConnectionStatus("connecting");
            connectWebSocket();
          }
        }, 3000);
      }
    };

    ws.onerror = () => {
      setError("WebSocket connection error");
      setConnectionStatus("error");
    };
  }, [wsUrl, mobile, cleanup]);

  useEffect(() => {
    connectWebSocket();

    const handleVisibilityChange = () => {
      if (
        document.visibilityState === "visible" &&
        (!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED)
      ) {
        connectWebSocket();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      cleanup();
    };
  }, [connectWebSocket, cleanup]);

  const renderTimeBlock = (value, label) => (
    <TimeBlock elevation={2}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          fontFamily: "monospace",
          color: theme.palette.primary.main,
        }}
      >
        {String(value).padStart(2, "0")}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {label}
      </Typography>
    </TimeBlock>
  );

  const renderSubscriptionStatus = () => {
    if (!subscriptionData) {
      return (
        <Box sx={{ p: 3, textAlign: "center" }}>
          <LinearProgress sx={{ mb: 2 }} />
          <Typography color="text.secondary">
            Loading subscription details...
          </Typography>
        </Box>
      );
    }

    if (subscriptionData.status === "EXPIRED") {
      return (
        <Alert severity="error" icon={<ErrorIcon />} sx={{ borderRadius: 2 }}>
          Your subscription has expired. Please renew to continue accessing our
          services.
        </Alert>
      );
    }

    if (subscriptionData.status === "NO_ACTIVE_SUBSCRIPTION") {
      return (
        <Alert severity="warning" sx={{ borderRadius: 2 }}>
          No active subscription found. Please subscribe to access our services.
        </Alert>
      );
    }

    const { remaining, planName, expiryDate, startDate } = subscriptionData;

    return (
      <Stack spacing={4}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <AccessTimeIcon color="primary" sx={{ fontSize: 32 }} />
          <Typography variant="h6">Time Remaining</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            {renderTimeBlock(remaining.days, "Days")}
          </Grid>
          <Grid item xs={6} sm={3}>
            {renderTimeBlock(remaining.hours, "Hours")}
          </Grid>
          <Grid item xs={6} sm={3}>
            {renderTimeBlock(remaining.minutes, "Minutes")}
          </Grid>
          <Grid item xs={6} sm={3}>
            {renderTimeBlock(remaining.seconds, "Seconds")}
          </Grid>
        </Grid>

        <Divider />

        <Stack spacing={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Chip
              label={`Plan: ${planName}`}
              color="primary"
              icon={<CheckCircleIcon />}
              sx={{
                fontWeight: "medium",
                borderRadius: 2,
                px: 1,
              }}
            />
            <Typography variant="body2" color="text.secondary">
              UID: {userData.uid}
            </Typography>
          </Box>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            sx={{ justifyContent: "space-between" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <CalendarIcon color="action" />
              <Typography variant="body2">
                Start: {new Date(startDate).toLocaleDateString()}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <CalendarIcon color="error" />
              <Typography variant="body2" color="error">
                Expires: {new Date(expiryDate).toLocaleDateString()}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  return (
    <Card
      elevation={3}
      sx={{
        maxWidth: 800,
        mx: "auto",
        borderRadius: 3,
        overflow: "visible",
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Stack spacing={4}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Avatar
              sx={{
                width: 56,
                height: 56,
                bgcolor: theme.palette.primary.main,
              }}
            >
              <AccountCircleIcon sx={{ fontSize: 32 }} />
            </Avatar>
            <Box>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {userData.username}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {userData.accountType}
              </Typography>
            </Box>
          </Box>

          {connectionStatus !== "connected" && (
            <Alert severity="info" sx={{ borderRadius: 2 }}>
              {connectionStatus === "connecting"
                ? "Connecting to server..."
                : "Connection lost. Attempting to reconnect..."}
            </Alert>
          )}

          {error && (
            <Alert severity="error" sx={{ borderRadius: 2 }}>
              {error}
            </Alert>
          )}

          {renderSubscriptionStatus()}

          <GradientCard elevation={4}>
            <Stack spacing={4}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <RocketIcon sx={{ fontSize: 40 }} />
                <Box>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Upgrade to Premium
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.9 }}>
                    Get access to exclusive features and priority support
                  </Typography>
                </Box>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FeatureCard>
                    <StarIcon sx={{ color: "#FFD700", fontSize: 32 }} />
                    <Typography variant="body2" align="center">
                      24/7 Priority Support
                    </Typography>
                  </FeatureCard>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FeatureCard>
                    <DiamondIcon sx={{ color: "#B9F6CA", fontSize: 32 }} />
                    <Typography variant="body2" align="center">
                      Advanced Analytics
                    </Typography>
                  </FeatureCard>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FeatureCard>
                    <ArrowUpwardIcon sx={{ color: "#90CAF9", fontSize: 32 }} />
                    <Typography variant="body2" align="center">
                      Unlimited Access
                    </Typography>
                  </FeatureCard>
                </Grid>
              </Grid>

              <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.12)" }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "space-between",
                  alignItems: { xs: "stretch", sm: "center" },
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Typography variant="body2">
                    Contact us for premium plans:
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Tooltip title="Call us">
                      <ContactButton
                        size="small"
                        onClick={() =>
                          (window.location.href = "tel:+1234567890")
                        }
                      >
                        <PhoneIcon fontSize="small" />
                      </ContactButton>
                    </Tooltip>
                    <Tooltip title="WhatsApp">
                      <ContactButton
                        size="small"
                        onClick={() => window.open("https://wa.me/1234567890")}
                      >
                        <WhatsAppIcon fontSize="small" />
                      </ContactButton>
                    </Tooltip>
                    <Tooltip title="Email us">
                      <ContactButton
                        size="small"
                        onClick={() =>
                          (window.location.href = "mailto:support@example.com")
                        }
                      >
                        <MailIcon fontSize="small" />
                      </ContactButton>
                    </Tooltip>
                  </Stack>
                </Box>

                <Button
                  variant="contained"
                  startIcon={<RocketIcon />}
                  sx={{
                    bgcolor: "common.white",
                    color: "primary.main",
                    "&:hover": {
                      bgcolor: alpha("#fff", 0.9),
                    },
                    px: 3,
                    py: 1,
                    borderRadius: 2,
                  }}
                  onClick={() => (window.location.href = "/upgrade")}
                >
                  Upgrade Now
                </Button>
              </Box>

              <Typography
                variant="caption"
                sx={{
                  opacity: 0.7,
                  textAlign: "center",
                  mt: 2,
                }}
              >
                * Premium plans starting from $99/month. Special offers
                available for annual subscriptions.
              </Typography>
            </Stack>
          </GradientCard>
        </Stack>
      </CardContent>
    </Card>
  );
};

// PropTypes
SubscriptionTimer.propTypes = {
  mobile: PropTypes.string.isRequired,
  wsUrl: PropTypes.string.isRequired,
  userData: PropTypes.shape({
    username: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
  }).isRequired,
};

export default SubscriptionTimer;
