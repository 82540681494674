import React from "react";
import AdminPanel from "./Admin";
import UpdateTurnOverMain from "./UpdateTurnOverMain";

function UpdateTurnOver() {
  return (
    <AdminPanel>
      <UpdateTurnOverMain />
    </AdminPanel>
  );
}

export default UpdateTurnOver;
