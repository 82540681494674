import React, { useEffect, useState } from "react";
import SuperAdmin from "./SuperAdmin";
import {
  Box,
  Paper,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Avatar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormControlLabel,
  Checkbox,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { domain } from "../../Components/config";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

const UpdateProfilePopup = ({ open, onClose, data, onSave }) => {
  const [formData, setFormData] = useState(data || {});

  useEffect(() => {
    if (data) {
      setFormData(data);
      console.log(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    onSave(formData); // Pass updated form data back to parent
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Profile</DialogTitle>
      <DialogContent>
        <TextField
          label="First Name"
          name="firstName"
          value={formData?.firstName || ""}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Last Name"
          name="lastName"
          value={formData?.lastName || ""}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Gender</InputLabel>
          <Select
            name="gender"
            value={formData?.gender || ""}
            onChange={handleChange}
          >
            <MenuItem value="MALE">Male</MenuItem>
            <MenuItem value="FEMALE">Female</MenuItem>
            <MenuItem value="OTHER">Other</MenuItem>
            <MenuItem value="PREFER_NOT_TO_SAY">Prefer Not to Say</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Company"
          name="company"
          value={formData?.company || ""}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={formData?.email || ""}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Username"
          name="username"
          value={formData?.username || ""}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone Number"
          name="phoneNumber"
          value={formData?.phoneNumber || ""}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Popup component to edit Journey details
const UpdateJourneyPopup = ({ open, onClose, data, onSave }) => {
  const [formData, setFormData] = useState(data || {});

  useEffect(() => {
    if (data) {
      setFormData(data); // Populate formData with current journey data
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    onSave(formData); // Pass updated journey data back to parent
  };

  // Helper function to format strings
  const formatText = (text) =>
    text
      .split("_")
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(" ");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Journey</DialogTitle>
      <DialogContent>
        {/* Current Stage - Enum */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Current Stage</InputLabel>
          <Select
            name="currentStage"
            value={formData?.currentStage || ""}
            onChange={handleChange}
          >
            {[
              "LEAD",
              "DUPLICATE_LEAD",
              "NEW_NOT_CONVERTED",
              "FOLLOW_UP_REQUIRED",
              "INTERESTED",
              "COLD",
              "WARM",
              "LANGUAGE_BARRIER",
              "DISQUALIFIED",
              "NATC",
              "HOT",
              "PAYMENT_ACTIVE",
            ].map((stage) => (
              <MenuItem key={stage} value={stage}>
                {formatText(stage)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Lead Source - Enum */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Lead Source</InputLabel>
          <Select
            name="leadSource"
            value={formData?.leadSource || ""}
            onChange={handleChange}
          >
            {[
              "WEBSITE",
              "REFERRAL",
              "SOCIAL_MEDIA",
              "DIRECT",
              "PARTNER",
              "OTHER",
            ].map((source) => (
              <MenuItem key={source} value={source}>
                {formatText(source)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Qualification Score */}
        <TextField
          label="Qualification Score"
          name="qualificationScore"
          type="number"
          value={formData?.qualificationScore || ""}
          onChange={handleChange}
          fullWidth
          margin="normal"
          inputProps={{ min: 0, max: 100 }}
        />

        {/* Assigned To (commented out) */}
        {/* <TextField
          label="Assigned To"
          name="assignedTo"
          value={formData?.assignedTo || ""}
          onChange={handleChange}
          fullWidth
          margin="normal"
        /> */}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddActivityPopup = ({ open, onClose, data, onSave }) => {
  const [formData, setFormData] = useState(
    data || {
      type: "",
      details: {},
      timestamp: new Date().toISOString(),
    }
  );

  useEffect(() => {
    if (data) {
      setFormData(data);
    } else {
      // Reset form if no data is provided
      setFormData({
        type: "",
        details: {},
        timestamp: new Date().toISOString(),
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      details: { ...formData.details, [name]: value },
    });
  };

  const handleSave = () => {
    onSave(formData); // Pass updated activity data back to parent
  };

  // Helper function to format strings
  const formatText = (text) =>
    text
      .split("_")
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(" ");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Activity</DialogTitle>
      <DialogContent>
        {/* Type selection */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Type</InputLabel>
          <Select
            name="type"
            value={formData.type || ""}
            onChange={handleChange}
          >
            {[
              "LOGIN",
              "FEATURE_USAGE",
              "SUPPORT_REQUEST",
              "PAYMENT_ATTEMPT",
              "DOCUMENT_VIEW",
              "SETTINGS_CHANGE",
              "TRIAL_ACTION",
              "CLIENT_UPDATE",
            ].map((option) => (
              <MenuItem key={option} value={option}>
                {formatText(option)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Details input */}
        <TextField
          label="Action"
          name="action"
          value={formData.details?.action || ""}
          onChange={handleDetailsChange}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Additional Info"
          name="additionalInfo"
          value={formData.details?.additionalInfo || ""}
          onChange={handleDetailsChange}
          fullWidth
          margin="normal"
          helperText="Optional additional details about the activity."
        />

        {/* Timestamp input */}
        <TextField
          label="Timestamp"
          name="timestamp"
          type="datetime-local"
          value={
            formData.timestamp
              ? new Date(formData.timestamp).toISOString().slice(0, 16)
              : ""
          }
          onChange={(e) =>
            setFormData({
              ...formData,
              timestamp: new Date(e.target.value).toISOString(),
            })
          }
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UpdateTrialPopup = ({ open, onClose, data, onSave }) => {
  const [trialData, setTrialData] = useState({
    engagementScore: 0,
    startDate: "",
    endDate: "",
    isActive: false,
    usage: [],
  });

  useEffect(() => {
    if (data) {
      setTrialData(data); // Update local state whenever data changes
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrialData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(trialData); // Call the save function with the updated data
    onClose(); // Close the popup after saving
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Trial Information</DialogTitle>
      <DialogContent>
        <TextField
          label="Engagement Score"
          type="number"
          name="engagementScore"
          value={trialData.engagementScore || ""} // Handle possible null or undefined
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="Start Date"
          type="date"
          name="startDate"
          value={
            trialData.startDate
              ? new Date(trialData.startDate).toISOString().split("T")[0]
              : ""
          }
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="End Date"
          type="date"
          name="endDate"
          value={
            trialData.endDate
              ? new Date(trialData.endDate).toISOString().split("T")[0]
              : ""
          }
          onChange={handleChange}
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={trialData.isActive}
              onChange={(e) =>
                setTrialData((prev) => ({
                  ...prev,
                  isActive: e.target.checked,
                }))
              }
            />
          }
          label="Active"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ClientDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const [clientData, setClientData] = useState(null);
  const [openProfilePopup, setOpenProfilePopup] = useState(false);
  const [openJourneyPopup, setOpenJourneyPopup] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [openActivityPopup, setOpenActivityPopup] = useState(false);
  const [openTrialPopup, setOpenTrialPopup] = useState(false);
  const [selectedTrial, setSelectedTrial] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [openSubscriptionPopup, setOpenSubscriptionPopup] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [journeyData, setJourneyData] = useState(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const fetchClientDetails = async () => {
    try {
      const response = await axios.get(`${domain}/client/${id}`, {
        withCredentials: true,
      });
      setClientData(response.data.data);
    } catch (error) {
      console.error("Error fetching client details:", error);
    }
  };
  useEffect(() => {
    fetchClientDetails();
  }, [id]);

  if (!clientData) {
    return (
      <SuperAdmin>
        <Box sx={{ padding: 2 }}>
          <Paper sx={{ padding: 4, textAlign: "center" }}>
            <CircularProgress />
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Loading client data...
            </Typography>
          </Paper>
        </Box>
      </SuperAdmin>
    );
  }

  const {
    username,
    email,
    phoneNumber,
    profile,
    journey,
    trial,
    activities,
    subscriptions,
  } = clientData;

  const handleProfileUpdate = async (updatedData) => {
    try {
      await axios.patch(`${domain}/users/${id}/profile`, updatedData, {
        withCredentials: true,
      });
      console.log("Profile updated successfully");
      setClientData((prevData) => ({
        ...prevData,
        profile: { ...prevData.profile, ...updatedData },
      }));
      fetchClientDetails();
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleJourneyUpdate = async (updatedData) => {
    try {
      await axios.patch(`${domain}/users/${id}/journey`, updatedData, {
        withCredentials: true,
      });
      setClientData((prevData) => ({
        ...prevData,
        journey: updatedData,
      }));
    } catch (error) {
      console.error("Error updating journey:", error);
    }
  };

  const editProfile = () => {
    setProfileData({
      ...clientData.profile,
      phoneNumber: clientData.phoneNumber,
      username: clientData.username,
      email: clientData.email,
    });
    setOpenProfilePopup(true);
  };

  const editJourney = () => {
    setJourneyData(clientData.journey);
    setOpenJourneyPopup(true);
  };

  const editTrial = () => {
    setSelectedTrial(trial); // Set the current trial data to be edited
    setOpenTrialPopup(true); // Open the popup for editing
  };

  const editSubscription = (subscription) => {
    setSelectedSubscription(subscription);
    setOpenSubscriptionPopup(true);
  };

  const handleSaveProfile = (updatedData) => {
    handleProfileUpdate(updatedData);
    setOpenProfilePopup(false);
  };

  const handleSaveJourney = (updatedData) => {
    handleJourneyUpdate(updatedData);
    setOpenJourneyPopup(false);
  };

  // Function to handle saving the updated trial information
  const handleTrialSave = async (updatedData) => {
    try {
      await axios.patch(`${domain}/users/${id}/trial`, updatedData, {
        withCredentials: true,
      });
      console.log(`Trial updated successfully`);
      // Optionally refetch client details to refresh the data
      const response = await axios.get(`${domain}/client/${id}`, {
        withCredentials: true,
      });
      setClientData(response.data.data); // Update clientData with fresh data
    } catch (error) {
      console.error(`Error updating trial:`, error);
    }
  };

  const handleActivitySave = async (newData) => {
    try {
      const response = await axios.post(
        `${domain}/users/${id}/activities`,
        newData,
        { withCredentials: true }
      );
      console.log("Activity added successfully");

      // Update the state to add the new activity to the list
      setClientData((prevData) => ({
        ...prevData,
        activities: [...prevData.activities, response.data],
      }));
    } catch (error) {
      console.error("Error adding activity:", error);
    }

    setOpenActivityPopup(false);
    fetchClientDetails();
  };

  const handleSubscriptionSave = async (updatedData) => {
    // Update locally
    setClientData((prevData) => {
      const updatedSubscriptions = prevData.subscriptions.map((sub) =>
        sub._id === updatedData._id ? updatedData : sub
      );
      return { ...prevData, subscriptions: updatedSubscriptions };
    });

    // Update on the server
    try {
      await axios.patch(`${domain}/users/${id}/subscriptions`, updatedData, {
        withCredentials: true,
      });
      console.log(`Subscription updated successfully`);
    } catch (error) {
      console.error("Error updating subscription:", error);
    }

    setOpenSubscriptionPopup(false);
  };

  const formatText = (text) => {
    if (!text) return ""; // Handle undefined or empty string cases
    return text
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  return (
    <SuperAdmin>
      <Box sx={{ padding: 2 }}>
        <Typography
          variant="h4"
          gutterBottom
          color={"#274BA6"}
          fontWeight={"bold"}
        >
          Client Details
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Column on small screens, row on medium and up
            gap: 2,
          }}
        >
          {/* Left Column - Profile, Trial, Subscriptions */}
          <Box
            sx={{
              flex: "0 0 350px",
              display: "flex",
              flexDirection: "column",
              marginBottom: 2,
              width: isMobile ? "100%" : "300px",
            }}
          >
            {/* Client Profile */}
            <Paper
              sx={{
                padding: 3,
                marginBottom: 2,
                borderRadius: 2,
                position: "relative",
              }}
            >
              <BorderColorOutlinedIcon
                fontSize="small"
                sx={{
                  position: "absolute",
                  right: 16,
                  cursor: "pointer",
                  color: "#274BA6",
                }}
                onClick={() => editProfile()}
              />
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{ py: 2 }}
              >
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Avatar
                    src={profile.picture}
                    alt={`${profile.firstName} ${profile.lastName}`}
                    sx={{ width: 80, height: 80, bgcolor: "#274BA6" }}
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Typography variant="h6">
                    {profile.firstName} {profile.lastName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {profile.company}
                  </Typography>
                  <Typography variant="body1">{email}</Typography>
                  <Typography variant="body1">{username}</Typography>
                  <Typography variant="body1">{phoneNumber}</Typography>
                </Grid>
              </Grid>
            </Paper>

            <UpdateProfilePopup
              open={openProfilePopup}
              onClose={() => setOpenProfilePopup(false)}
              data={profileData}
              onSave={handleSaveProfile}
            />

            {/* Trial */}
            <Paper
              sx={{
                padding: 3,
                marginBottom: 2,
                borderRadius: 2,
                position: "relative",
              }}
            >
              <BorderColorOutlinedIcon
                fontSize="small"
                sx={{
                  position: "absolute",
                  right: 16,
                  cursor: "pointer",
                  color: "#274BA6",
                }}
                onClick={editTrial}
              />
              <Typography variant="h5" gutterBottom color={"#274BA6"}>
                Trial Information
              </Typography>
              <Typography variant="body1">
                Active: {trial?.isActive ? "Yes" : "No"}
              </Typography>
              <Typography variant="body1">
                Start Date: {new Date(trial?.startDate).toLocaleDateString()}
              </Typography>
              <Typography variant="body1">
                End Date: {new Date(trial?.endDate).toLocaleDateString()}
              </Typography>
              <Typography variant="body1">
                Engagement Score: {trial?.engagementScore}
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Usage:
              </Typography>
              <List dense>
                {trial?.usage.map((usage, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`Feature: ${usage.feature}`}
                      secondary={`Usage Count: ${
                        usage.count
                      }, Last Used: ${new Date(
                        usage.lastUsed
                      ).toLocaleString()}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>

            <UpdateTrialPopup
              open={openTrialPopup}
              onClose={() => setOpenTrialPopup(false)}
              data={selectedTrial}
              onSave={handleTrialSave}
            />

            {/* Subscriptions */}
            <Paper
              sx={{
                padding: 3,
                marginBottom: 3,
                borderRadius: 2,
                position: "relative",
              }}
            >
              <Typography variant="h5" gutterBottom color={"#274BA6"}>
                Subscriptions
              </Typography>
              <List dense>
                {subscriptions.map((subscription, index) => (
                  <ListItem key={index} sx={{ position: "relative" }}>
                    <ListItemText
                      primary={`${subscription.planName} - Status: ${subscription.status}`}
                      secondary={`Price: ${subscription.price.amount} ${
                        subscription.price.currency
                      } | Expires: ${new Date(
                        subscription.expiryDate
                      ).toLocaleDateString()}`}
                    />
                    <BorderColorOutlinedIcon
                      fontSize="small"
                      sx={{
                        position: "absolute",
                        right: 16,
                        cursor: "pointer",
                        color: "#274BA6",
                      }}
                      onClick={() => editSubscription(subscription)}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Box>

          {/* Right Column - Journey, Activities */}
          <Box
            sx={{
              flex: "1 1", // Take the remaining space
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Journey */}
            <Paper
              sx={{
                padding: 3,
                marginBottom: 3,
                borderRadius: 2,
                position: "relative",
              }}
            >
              <BorderColorOutlinedIcon
                fontSize="small"
                sx={{
                  position: "absolute",
                  right: 16,
                  cursor: "pointer",
                  color: "#274BA6",
                }}
                onClick={editJourney}
              />
              <Typography variant="h5" gutterBottom color={"#274BA6"}>
                Journey
              </Typography>
              <Typography variant="body1">
                Current Stage: {formatText(journey.currentStage)}
              </Typography>
              <Typography variant="body1">
                Qualification Score: {journey.qualificationScore}
              </Typography>
              <Typography variant="body1">
                Lead Source: {formatText(journey.leadSource)}
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginTop: 2, fontWeight: 600 }}
              >
                Journey Stages:
              </Typography>
              <List dense>
                {journey.previousStages.map((stage, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`${formatText(
                        stage.stage
                      )} - Entered at: ${new Date(
                        stage.enteredAt
                      ).toLocaleString()}`}
                      secondary={`Notes: ${stage.notes}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>

            <UpdateJourneyPopup
              open={openJourneyPopup}
              onClose={() => setOpenJourneyPopup(false)}
              data={journeyData}
              onSave={handleSaveJourney}
            />

            {/* Activities */}
            <Paper
              sx={{
                padding: 3,
                marginBottom: 3,
                borderRadius: 2,
                position: "relative",
              }}
            >
              <Typography variant="h5" gutterBottom color={"#274BA6"}>
                Recent Activities
              </Typography>
              <List dense>
                {activities.map((activity, index) => (
                  <ListItem key={index} sx={{ position: "relative" }}>
                    <ListItemText
                      primary={`${formatText(activity.type)} - ${
                        activity?.details?.action
                          ? activity?.details.action
                          : ""
                      }`}
                      secondary={`Timestamp: ${new Date(
                        activity.timestamp
                      ).toLocaleString()}`}
                    />
                  </ListItem>
                ))}
              </List>

              {/* Button to open the Add Activity Popup */}
              <Button
                variant="contained"
                onClick={() => {
                  setSelectedActivity(null); // Clear selected activity
                  setOpenActivityPopup(true);
                }}
                sx={{ marginTop: 2, bgcolor: "#274BA6" }}
              >
                Add Activity
              </Button>
            </Paper>

            <AddActivityPopup
              open={openActivityPopup}
              onClose={() => setOpenActivityPopup(false)}
              data={selectedActivity}
              onSave={handleActivitySave}
            />
          </Box>
        </Box>
      </Box>
    </SuperAdmin>
  );
};

export default ClientDetails;
