import React from "react";
import AdminPanel from "./Admin";
import InvitationBonusMain from "./InvitationBonusMain";

function InvitationBonus() {
  return (
    <AdminPanel>
      <InvitationBonusMain />
    </AdminPanel>
  );
}

export default InvitationBonus;
