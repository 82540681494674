import React, { useState } from "react";
import {
  Grid,
  Card,
  Paper,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  Chip,
  TextField,
  Divider,
  CircularProgress,
  Alert,
  useTheme,
  useMediaQuery,
  Fade,
  Container,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Sparkles } from "lucide-react";
import { ToastContainer } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

// PlanSelection Component
export const PlanSelection = ({
  plans,
  formData,
  setFormData,
  handleBack,
  handleNext,
  loading,
  isNextButtonDisabled,
}) => {
  return (
    <Box
      sx={{
        p: 4,
        bgcolor: "#fff",
        borderRadius: "0px",
        margin: "0 auto",
        minHeight: "95vh",
        position: "relative",
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%", // Adjust this to control width beyond 100%
          height: "100%",
          backgroundImage: `url('/assets/3623.jpg')`, // Replace with your image URL
          backgroundSize: "cover", // Adjust as needed
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          clipPath: "polygon(100% 0, 100% 100%, 0 100%)", // Increase the scale factor as needed
          zIndex: 0,
        },
      }}
    >
      <Paper
        elevation={3} // Adjust elevation for shadow effect
        sx={{
          bgcolor: "#fff", // White background
          borderRadius: "16px", // Rounded corners
          padding: 2.1, // Padding around the content
          position: "relative", // Position for any absolute elements inside
          margin: "5rem auto 0", // Center the paper in the parent container
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            mb: 2,
            fontSize: "28px",
            position: "relative", // Ensure text is above the background
            zIndex: 1,
          }}
        >
          Choose your plan
        </Typography>

        <Grid
          container
          spacing={4}
          sx={{ position: "relative", zIndex: 1, mx: "auto" }}
        >
          {plans.map((plan) => (
            <Grid item xs={12} md={4} key={plan._id}>
              <Card
                elevation={formData.chosenPlanId === plan._id ? 8 : 2}
                sx={{
                  height: "100%",
                  display: "flex",
                  width: "90%",
                  flexDirection: "column",
                  borderRadius: "16px",
                  position: "relative",
                  transition: "all 0.3s ease",
                  transform:
                    formData.chosenPlanId === plan._id
                      ? "scale(1.02)"
                      : "scale(1)",
                  cursor: "pointer",
                  overflow: "visible",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                  zIndex: 1, // Ensure card is above the background
                }}
                onClick={() =>
                  setFormData((prev) => ({ ...prev, chosenPlanId: plan._id }))
                }
              >
                <CardContent sx={{ flexGrow: 1, p: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 2,
                      color:
                        plan._id === "basic"
                          ? "#6366F1"
                          : plan._id === "standard"
                          ? "#430141"
                          : "#7C3AED",
                      fontWeight: 500,
                      fontSize: "18px",
                    }}
                  >
                    {plan.planName}
                  </Typography>

                  <Box sx={{ display: "flex", alignItems: "baseline", mb: 3 }}>
                    <Typography
                      variant="h4"
                      component="span"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "32px",
                      }}
                    >
                      {plan.price.currency} {plan.price.amount}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{
                        color: "text.secondary",
                        ml: 0.5,
                        fontSize: "14px",
                      }}
                    >
                      {plan.planType}
                    </Typography>
                  </Box>

                  <Box sx={{ "& > *": { mt: 2.5 } }}>
                    {plan.planDescriptions.map((description, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1.5,
                          color: "text.secondary",
                        }}
                      >
                        <AddIcon
                          sx={{
                            fontSize: "18px",
                            color:
                              plan._id === "basic"
                                ? "#6366F1"
                                : plan._id === "standard"
                                ? "#430141"
                                : "#7C3AED",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "text.primary",
                          }}
                        >
                          {description}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </CardContent>

                <CardActions sx={{ p: 4, pt: 1 }}>
                  <Button
                    fullWidth
                    variant={
                      formData.chosenPlanId === plan._id
                        ? "contained"
                        : "outlined"
                    }
                    sx={{
                      borderRadius: "24px",
                      py: 1.5,
                      textTransform: "none",
                      background:
                        formData.chosenPlanId === plan._id
                          ? "linear-gradient(45deg, #b06949, #682a41)"
                          : "transparent",
                      borderColor: "#430141",
                      color:
                        formData.chosenPlanId === plan._id
                          ? "white"
                          : "#430141",
                      fontWeight: 500,
                      "&:hover": {
                        background:
                          formData.chosenPlanId === plan._id
                            ? "linear-gradient(45deg, #b06949, #682a41)"
                            : "transparent",
                        borderColor: "#430141",
                      },
                    }}
                  >
                    Get Plan
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 4,
          position: "relative",
          zIndex: 1,
        }}
      >
        <Button
          onClick={handleBack}
          sx={{ mr: 1, color: "#fff" }}
          disabled={loading}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={isNextButtonDisabled}
          style={{
            backgroundColor: "#fff",
            color: "#000",
            textTransform: "initial",
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const PlanConfirmation = ({
  plans,
  formData,
  handleBack,
  handleNext,
  loading,
}) => {
  const theme = useTheme();

  if (!plans.length) return null;
  const selectedPlan = plans.find((p) => p._id === formData.chosenPlanId);
  if (!selectedPlan) return null;

  const getPlanColor = (planId) => {
    switch (planId) {
      case "basic":
        return "#6366F1";
      case "standard":
        return "#16A34A";
      case "premium":
        return "#7C3AED";
      default:
        return "#6366F1";
    }
  };

  const planColor = getPlanColor(selectedPlan._id);

  const HeaderPattern = () => (
    <Box
      sx={{
        height: "80px",
        width: "100%",
        backgroundImage: `url('/assets/5774968.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        marginBottom: 6,
        opacity: 0.9,
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "20px",
          background: "linear-gradient(to top, white, transparent)",
        },
      }}
    />
  );

  return (
    <Fade in timeout={500}>
      <Box sx={{ bgcolor: "whitesmoke", minHeight: "80vh" }}>
        <HeaderPattern />

        <Container maxWidth="md" sx={{ pt: 0, pb: 8 }}>
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Typography variant="h4" sx={{ fontWeight: 800, mb: 2 }}>
              A great big thank you!
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Welcome to the Anotech India solutions family! We'll take it from
              here.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              We've sent a confirmation email to {formData.email}
            </Typography>

            {/* New conditional message for paid plans */}
            {selectedPlan.planType !== "TRIAL" && (
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  borderRadius: 3,
                  position: "relative",
                  background:
                    "linear-gradient(135deg, #6366F1 0%, #7C3AED 100%)",
                  boxShadow: "0 10px 20px rgba(99, 102, 241, 0.15)",
                  overflow: "hidden",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background:
                      "radial-gradient(circle at top right, rgba(255,255,255,0.1) 0%, transparent 60%)",
                    zIndex: 1,
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    zIndex: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1.5,
                      mb: 1,
                    }}
                  >
                    <Sparkles size={24} color="white" />
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white",
                        fontWeight: 700,
                        textTransform: "capitalize",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Thank you for choosing our{" "}
                      {selectedPlan.planType === "MONTHLY"
                        ? "Monthly"
                        : "Lifetime"}{" "}
                      plan!
                    </Typography>
                    <Sparkles size={24} color="white" />
                  </Box>

                  <Typography
                    variant="body1"
                    sx={{
                      color: "rgba(255, 255, 255, 0.9)",
                      lineHeight: 1.3,
                      textAlign: "center",
                    }}
                  >
                    Once you complete your registration, our support and sales
                    team will reach out to you shortly to help you get started
                    and answer any questions. We're here to ensure a seamless
                    onboarding experience!
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Paper sx={{ p: 3, minHeight: "50vh" }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                  Details
                </Typography>

                <Box sx={{ mb: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    You purchased the {selectedPlan.planName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    You will be charged {selectedPlan.price.currency}
                    {selectedPlan.price.amount}
                  </Typography>
                </Box>
                <Box sx={{ pt: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 600, mb: 1 }}
                  >
                    This plan includes
                  </Typography>
                  {selectedPlan.planDescriptions?.map((description, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      spacing={1.5}
                      alignItems="center"
                      sx={{ mb: 1.5 }}
                    >
                      <CheckCircleOutlineIcon
                        sx={{
                          color: planColor,
                          fontSize: 18,
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          color: "text.secondary",
                        }}
                      >
                        {description}
                      </Typography>
                    </Stack>
                  ))}
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 600, mb: 1 }}
                  >
                    Primary Contact
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formData.firstName} {formData.lastName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formData.mobile}
                  </Typography>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Stack spacing={1}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Subtotal
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {selectedPlan.price.currency}
                      {selectedPlan.price.amount}
                    </Typography>
                  </Box>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fade>
  );
};

const InfoField = ({ label, value }) => (
  <Box>
    <Typography
      variant="caption"
      sx={{
        color: "text.secondary",
        fontWeight: 500,
        display: "block",
        mb: 0.5,
      }}
    >
      {label}
    </Typography>
    <Typography
      variant="body2"
      sx={{
        color: "text.primary",
        fontWeight: 500,
      }}
    >
      {value}
    </Typography>
  </Box>
);
