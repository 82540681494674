import React, { useEffect, useState } from "react";
import SuperAdmin from "./SuperAdmin";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
  Chip,
  Stack,
  Divider,
  alpha,
} from "@mui/material";
import {
  Add as AddIcon,
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  DeleteOutline as DeleteIcon,
  AccessTime as TimeIcon,
  AutorenewRounded as RenewIcon,
  ArrowForward as ArrowIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { domain } from "../../Components/config";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   borderRadius: "10px",
//   boxShadow: 24,
//   p: 4,
// }

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[10],
    "&:before": {
      opacity: 1,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(45deg, ${alpha(
      theme.palette.primary.light,
      0.05
    )}, ${alpha(theme.palette.primary.main, 0.05)})`,
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
}));

// const GradientTypography = styled(Typography)(({ theme }) => ({
//   background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
//   backgroundClip: 'text',
//   WebkitBackgroundClip: 'text',
//   color: 'transparent',
//   fontWeight: 'bold'
// }));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

const SuperAdminPlans = () => {
  const [plans, setPlans] = useState(null);
  const [open, setOpen] = useState(false);
  const [isAddingNewPlan, setIsAddingNewPlan] = useState(false);
  const [formData, setFormData] = useState({
    planName: "",
    price: "",
    planType: "",
    expiresInValue: "",
    expiresInUnit: "days",
    planDescriptions: [""],
  });
  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${domain}/get-plans`, {
        withCredentials: true,
      });
      console.log(response.data);
      setPlans(response.data.data);
    } catch (error) {
      console.error("Error fetching client analytics:", error);
    }
  };
  useEffect(() => {
    fetchPlans();
  }, []);
  const handleOpen = (plan = null) => {
    if (plan) {
      // Manage plan case
      setIsAddingNewPlan(false);
      const expiresInParts = plan?.expiresIn.split(" ");
      const expiresInValue = expiresInParts[0];
      const expiresInUnit = expiresInParts[1]?.toLowerCase();

      setFormData({
        planName: plan.planName,
        price: plan?.price?.amount,
        planType: plan.planType,
        expiresInValue: expiresInValue,
        expiresInUnit: expiresInUnit,
        expiresIn: plan.expiresIn,
        planDescriptions: plan.planDescriptions,
        id: plan?._id,
      });
    } else {
      // Add new plan case
      setIsAddingNewPlan(true);
      setFormData({
        planName: "",
        price: "",
        planType: "",
        expiresInValue: 1,
        expiresInUnit: "minutes",
        expiresIn: "1 minutes",
        planDescriptions: [""],
        id: null,
      });
    }
    setOpen(true);
  };

  const handleDeletePlan = async (id) => {
    try {
      const response = await axios.delete(`${domain}/delete-plans/${id}`, {
        withCredentials: true,
      });
      console.log(response.data);
      alert("Plan deleted successfully");
    } catch (error) {
      console.error("Error deleting plan:", error);
    } finally {
      handleClose();
      fetchPlans();
    }
  };

  const handleSavePlan = async (id) => {
    const {
      planName,
      planDescriptions,
      planType,
      price,
      expiresIn,
      autoRenewal,
    } = formData;

    // Prepare the data for API
    const dataToSend = {
      planName,
      planDescriptions,
      planType,
      price: {
        amount: price, // Ensure this matches the expected structure
        currency: "₹", // or whatever currency you want to use
      },
      expiresIn, // Send the combined expiresIn string
      autoRenewal: false, // Set this based on your logic or UI
    };

    try {
      if (isAddingNewPlan) {
        // Send POST request to add new plan
        const response = await axios.post(`${domain}/add-plans`, dataToSend, {
          withCredentials: true,
        });
        console.log("Plan added:", response.data);
      } else {
        const response = await axios.put(
          `${domain}/update-plans/${id}`,
          dataToSend,
          {
            withCredentials: true,
          }
        );
        console.log("Plan updated:", response.data);
      }
    } catch (error) {
      console.error("Error saving plan:", error);
    } finally {
      setOpen(false);
      fetchPlans();
    }
  };

  const handleClose = () => setOpen(false);

  // Update the handleChange function to handle minute-based plans
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newFormData = { ...formData, [name]: value };

    // Handle planDescriptions separately
    if (name === "planDescriptions") {
      newFormData = {
        ...newFormData,
        planDescriptions: value.split(","),
      };
    }

    // Set default expiration unit to minutes when MINUTE plan type is selected
    if (name === "planType" && value === "MINUTE") {
      newFormData = {
        ...newFormData,
        expiresInUnit: "minutes",
        expiresIn: `${newFormData.expiresInValue || 1} minutes`,
      };
    }

    // Automatically update expiresIn when either expiresInValue or expiresInUnit changes
    if (name === "expiresInValue" || name === "expiresInUnit") {
      newFormData = {
        ...newFormData,
        expiresIn: `${newFormData.expiresInValue} ${newFormData.expiresInUnit}`,
      };
    }

    setFormData(newFormData);
  };
  const handleAddDescription = () => {
    setFormData((prevState) => ({
      ...prevState,
      planDescriptions: [...prevState.planDescriptions, ""],
    }));
  };

  const handleDescriptionChange = (index, value) => {
    const newDescriptions = [...formData.planDescriptions];
    newDescriptions[index] = value;
    setFormData((prevState) => ({
      ...prevState,
      planDescriptions: newDescriptions,
    }));
  };

  return (
    <SuperAdmin>
      <Box sx={{ minHeight: "100vh", bgcolor: "grey.50", py: 6 }}>
        <Box maxWidth="xl" mx="auto" px={3}>
          {/* Plans Grid */}
          <Grid container spacing={4}>
            {plans?.map((plan) => (
              <Grid item xs={12} md={6} lg={4} key={plan._id}>
                <StyledCard>
                  <CardContent
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* Plan Header */}
                    <Box mb={3}>
                      <Chip
                        label={plan.planType}
                        size="small"
                        sx={{
                          mb: 1,
                          backgroundColor: "#274BA6",
                          color: "#fff",
                        }}
                      />
                      <Typography variant="h4" fontWeight="bold" gutterBottom>
                        {plan.planName}
                      </Typography>

                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        color="#274BA6"
                      >
                        {plan.price.currency}
                        {plan.price.amount}
                        <Typography
                          component="span"
                          variant="h6"
                          color="text.secondary"
                        >
                          /
                          {plan.planType === "MINUTE"
                            ? "minute"
                            : plan.planType.toLowerCase()}
                        </Typography>
                      </Typography>
                    </Box>

                    {/* Features List */}
                    <List disablePadding sx={{ mb: "auto" }}>
                      {plan.planDescriptions.map((desc, index) => (
                        <ListItem key={index} disablePadding sx={{ mb: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <CheckCircleIcon color="success" fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primary={desc}
                            primaryTypographyProps={{ variant: "body1" }}
                          />
                        </ListItem>
                      ))}
                    </List>

                    {/* Plan Details */}
                    <Divider sx={{ my: 2 }} />
                    <Stack spacing={1} mb={2}>
                      <Box display="flex" alignItems="center" gap={1}>
                        <TimeIcon fontSize="small" color="action" />
                        <Typography variant="body2" color="text.secondary">
                          Expires in: {plan.expiresIn}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" gap={1}>
                        <RenewIcon fontSize="small" color="action" />
                        <Typography variant="body2" color="text.secondary">
                          Auto-renewal: {plan.autoRenewal ? "Yes" : "No"}
                        </Typography>
                      </Box>
                    </Stack>
                  </CardContent>

                  {/* Action Button */}
                  <CardActions sx={{ p: 2, pt: 0 }}>
                    <Button
                      variant="contained"
                      fullWidth
                      size="large"
                      onClick={() => handleOpen(plan)}
                      endIcon={<ArrowIcon />}
                      sx={{
                        background: `#274BA6`,
                        boxShadow: 2,
                        "&:hover": {
                          boxShadow: 4,
                        },
                      }}
                    >
                      Manage Plan
                    </Button>
                  </CardActions>
                </StyledCard>
              </Grid>
            ))}

            {/* Add New Plan Card */}
            <Grid item xs={12} md={6} lg={4}>
              <StyledCard
                sx={{
                  height: "100%",
                  bgcolor: "background.paper",
                  cursor: "pointer",
                  border: "2px dashed",
                  borderColor: "divider",
                  "&:hover": {
                    borderColor: "primary.main",
                    bgcolor: "action.hover",
                  },
                }}
                onClick={() => handleOpen()}
              >
                <CardContent
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 4,
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: "#274BA6",
                      borderRadius: "50%",
                      p: 2,
                      mb: 2,
                    }}
                  >
                    <AddIcon fontSize="large" sx={{ color: "#ffffff" }} />
                  </Box>
                  <Typography variant="h6" color="#274BA6" gutterBottom>
                    Add New Plan
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Create a custom subscription plan
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>

          {/* Modal */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
          >
            <Box sx={modalStyle}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={3}
              >
                <Typography variant="h5" component="h2" id="modal-title">
                  {isAddingNewPlan ? "Create New Plan" : "Edit Plan"}
                </Typography>
                <IconButton onClick={handleClose} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>

              <Stack spacing={3}>
                {/* Plan Name */}
                <TextField
                  label="Plan Name"
                  name="planName"
                  fullWidth
                  value={formData.planName}
                  onChange={handleChange}
                />
                {/* Plan Type */}
                <FormControl fullWidth>
                  <InputLabel id="plan-type-label">Plan Type</InputLabel>
                  <Select
                    labelId="plan-type-label"
                    name="planType"
                    value={formData.planType}
                    onChange={handleChange}
                    label="Plan Type"
                  >
                    <MenuItem value="TRIAL">Trial</MenuItem>
                    <MenuItem value="MINUTE">Minute</MenuItem>
                    <MenuItem value="MONTHLY">Monthly</MenuItem>
                    <MenuItem value="ANNUAL">Annual</MenuItem>
                    <MenuItem value="LIFETIME">Lifetime</MenuItem>
                  </Select>
                </FormControl>
                {/* Price */}
                <TextField
                  label="Price (₹)"
                  name="price"
                  type="number"
                  fullWidth
                  value={formData.price}
                  onChange={handleChange}
                />
                {/* Plan Descriptions */}
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    Plan Features
                  </Typography>
                  <Stack spacing={2}>
                    {formData.planDescriptions.map((desc, index) => (
                      <Box key={index} display="flex" gap={1}>
                        <TextField
                          fullWidth
                          value={desc}
                          onChange={(e) =>
                            handleDescriptionChange(index, e.target.value)
                          }
                          placeholder={`Feature ${index + 1}`}
                        />
                        {index > 0 && (
                          <IconButton
                            color="error"
                            onClick={() => {
                              const newDescriptions =
                                formData.planDescriptions.filter(
                                  (_, i) => i !== index
                                );
                              setFormData((prev) => ({
                                ...prev,
                                planDescriptions: newDescriptions,
                              }));
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={handleAddDescription}
                    >
                      Add Feature
                    </Button>
                  </Stack>
                </Box>
                {/* Expiration */}
                <Box display="flex" gap={2}>
                  <TextField
                    label="Duration"
                    name="expiresInValue"
                    type="number"
                    fullWidth
                    value={formData.expiresInValue}
                    onChange={handleChange}
                  />
                  <FormControl fullWidth>
                    <InputLabel>Unit</InputLabel>
                    <Select
                      name="expiresInUnit"
                      value={formData.expiresInUnit}
                      onChange={handleChange}
                      label="Unit"
                    >
                      <MenuItem value="minutes">Minutes</MenuItem>
                      <MenuItem value="days">Days</MenuItem>
                      <MenuItem value="months">Months</MenuItem>
                      <MenuItem value="years">Years</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {/* Action Buttons */}
                <Box display="flex" justifyContent="space-between" mt={2}>
                  {!isAddingNewPlan && (
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        handleDeletePlan(formData.id);
                        handleClose();
                      }}
                    >
                      Delete Plan
                    </Button>
                  )}
                  <Box display="flex" gap={2} ml="auto">
                    <Button variant="outlined" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleSavePlan(formData.id);
                        handleClose();
                      }}
                    >
                      {isAddingNewPlan ? "Create Plan" : "Update Plan"}
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Modal>
        </Box>
      </Box>
    </SuperAdmin>
  );
};

export default SuperAdminPlans;
