import React from "react";
import AdminPanel from "./Admin";
import AdditionalFeaturesMain from "./AdditionalFeaturesMain";

function AdditionalFeatures() {
  return (
    <AdminPanel>
      <AdditionalFeaturesMain />
    </AdminPanel>
  );
}

export default AdditionalFeatures;
