import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import SubscriptionTimer from "./SubscriptionTimer";
import axios from "axios";
import { domain, wssdomain } from "../../Components/config";
import AdminPanel from "./Admin";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
  },
});

const Subcription = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${domain}/user`, {
          withCredentials: true,
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  if (!userData) {
    return null;
  }

  return (
    <AdminPanel>
      <ThemeProvider theme={theme}>
        <SubscriptionTimer
          mobile={userData.mobile}
          wsUrl={wssdomain}
          userData={userData}
        />
      </ThemeProvider>
    </AdminPanel>
  );
};

export default Subcription;
