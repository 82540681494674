import AdminPanel from "./Admin";
import Coupenmain from "./Coupenmain";

const Coupen = ({}) => {
  return (
    <AdminPanel>
      <Coupenmain />
    </AdminPanel>
  );
};

export default Coupen;
